import {
  steps,
  showDemographics,
  showScreenRecordingWarning,
  showPersonalInfo,
} from "../utils/utils";

const reducer = (state = [], action) => {
  switch (action.type) {
    case "SESSION_ID":
      return { ...state, sessionId: action.payload };

    case "GET_SURVEY":
      var qState = {};
      if (action.payload.questions) {
        for (let i = 0; i < action.payload.questions.length; i++) {
          qState[i] = {
            requestState: "PENDING_INPUT",
            data: {},
            countdown:
              action.payload.questions[i].includeCountdownTimer &&
              action.payload.questions[i].countdownTimer
                ? action.payload.questions[i].countdownTimer
                : 0,
          };
        }
      }
      return {
        ...state,
        survey: action.payload,
        question:
          action.payload.questions && action.payload.questions.length
            ? action.payload.questions[0]
            : null,
        questionState: qState,
        audioRecordingFormat: action.payload.audioRecordingFormat
          ? action.payload.audioRecordingFormat
          : "wav",
      };

    case "SURVEY_ERROR":
      return { ...state, surveyError: action.payload, step: -1 };

    case "QUESTION_STATE":
      if (action.payload)
        return {
          ...state,
          questionState: {
            ...state.questionState,
            [state.questionIdx]: {
              ...state.questionState[state.questionIdx],
              ...action.payload,
            },
          },
        };
      break;

    case "NEXT_QUESTION":
      if (action.popQuestionStack) state.questionStack.pop();
      return {
        ...state,
        questionIdx: state.questionIdx + 1,
        questionStack: [...state.questionStack, state.questionIdx + 1],
        question:
          state.survey.questions.length > state.questionIdx
            ? state.survey.questions[state.questionIdx + 1]
            : null,
      };

    case "NEXT_STEP":
      return {
        ...state,
        step: state.step + 1,
        questionStack:
          state.step === steps.QUESTION - 1 && state.survey.questions.length
            ? [0]
            : state.questionStack,
      };

    case "SKIP_TO_QUESTION":
      if (action.popQuestionStack) state.questionStack.pop();
      return {
        ...state,
        questionIdx: action.payload,
        question: state.survey.questions[action.payload],
        questionStack: [...state.questionStack, action.payload],
      };

    case "PREV_STEP":
      if (state.step === steps.WELCOME) return state;
      if (
        state.step === steps.QUESTION &&
        state.questionIdx > 0 &&
        state.questionStack.length > 1
      )
        return {
          ...state,
          questionStack: state.questionStack.slice(
            0,
            state.questionStack.length - 1
          ),
          questionIdx:
            state.questionStack.length > 1
              ? state.questionStack[state.questionStack.length - 2]
              : 0,
          question:
            state.questionStack.length > 1
              ? state.survey.questions[
                  state.questionStack[state.questionStack.length - 2]
                ]
              : null,
        };
      let nextStep = state.step - 1;
      if (nextStep === steps.DEMOGRAPHICS && !showDemographics(state.survey))
        nextStep--;
      if (nextStep === steps.PERSONAL_INFO && !showPersonalInfo(state.survey))
        nextStep--;
      if (nextStep === steps.MIC_CHECK && !state.survey.includeMicCheck)
        nextStep--;
      if (nextStep === steps.CONSENT && !state.survey.consentPDFUri) nextStep--;
      if (nextStep === steps.SUMMARY && !state.survey.summary) nextStep--;
      if (
        nextStep === steps.WARNING &&
        !showScreenRecordingWarning(state.survey)
      )
        nextStep--;
      return {
        ...state,
        step: nextStep,
      };
    case "SCREEN_OUT":
      return { ...state, step: steps.SCREEN_OUT };
    case "ADD_DEMOGRAPHICS":
      return {
        ...state,
        demographics: { ...state.demographics, ...action.payload },
      };
    case "PREVIEW":
      return { ...state, preview: true };
    case "LANGUAGE":
      return { ...state, language: action.payload };
    case "UPDATE_CONDITION":
      return {
        ...state,
        conditions: { ...state.conditions, ...action.payload },
      };
    case "SET_SESSION_TIME":
      return { ...state, sessionMs: action.payload };
    case "RESET_SESSION_TIME":
      return { ...state, sessionMs: 0 };
    case "SHOW_TOAST":
      return { ...state, toastOpen: true, toastMessage: action.payload };
    case "CLEAR_TOAST":
      return { ...state, toastOpen: false };
    case "FEEDBACK":
      return { ...state, feedback: { ...state.feedback, ...action.payload } };
    case "UPDATE_REQUEST_STATES":
      return {
        ...state,
        requestStates: { ...state.requestStates, ...action.payload },
      };
    default:
      return state;
  }
};

export default reducer;
