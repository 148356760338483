import React from "react";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "white",
    height: 48,
    minWidth: 145,
    fontSize: 20,
    padding: "12px 20px",
    marginTop: 10,
    marginBottom: 10,
    "&:hover": {
      boxShadow: "none",
    },
    [theme.breakpoints.down("xs")]: {
      borderRadius: 8,
      height: 40,
      fontSize: 16,
      padding: "6px 10px",
      minWidth: 125,
    },
  },
}));

const GeneralButton = ({ className, children, ...other }) => {
  const classes = useStyles();

  return (
    <Button className={clsx(classes.root, className, "main-button")} {...other}>
      {children}
    </Button>
  );
};

export default GeneralButton;
