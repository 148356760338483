import { localize, strpLocalize } from "../localization/localize";
import { singlePartUpload } from "../s3";
import { persistor } from "./store";
import { track } from "../analytics";
import { dispatchEventSurveyLoaded } from "../events";
import {
  getVideoFileExtension,
  mapShortCodeToDialect,
  getDeviceType,
  convertTranslationsSchema,
  convertLangCodeToISO639,
  addQueryParams,
  parseQueryString,
  steps,
  isEmbed,
} from "../utils/utils";
import _ from "lodash";

// let BASE_URL = "https://api.phonic.ai/respond";
let BASE_URL = "https://backend-cap-group-demo-dot-phonic-2.wl.r.appspot.com/respond";
// if (process.env.REACT_APP_BACKEND_ENV === "LOCAL") {
//   BASE_URL = "http://127.0.0.1:8080/respond";
// } else if (process.env.REACT_APP_BACKEND_ENV === "STAGING") {
//   BASE_URL = "https://backend-staging-dot-phonic-2.wl.r.appspot.com/respond";
// }

const publicIp = require("public-ip");

export function getSurvey(surveyId, opts) {
  return (dispatch, getState) => {
    let url = new URL(`${BASE_URL}/${surveyId}`);
    if (opts.staging) {
      url.searchParams.set("staging", true);
    }
    return fetch(url)
      .then((response) => {
        if (response.status >= 400 && response.status < 600) {
          throw new Error("INVALID_SURVEY");
        } else if (response.status === 202) {
          dispatch({
            type: "SURVEY_ERROR",
            payload: "CLOSED_SURVEY",
          });
        }
        return response.json();
      })
      .then((survey) => {
        // Resume persistence if not a draft or preview.
        console.log(survey);
        if (
          survey.persistResponses == true &&
          !getState().preview &&
          survey.state !== "DRAFT"
        ) {
          persistor.persist();
        }

        if (getState().language) {
          localize.setLocale(getState().language);
        } else if (survey.defaultLanguage) {
          localize.setLocale(survey.defaultLanguage);
          dispatch({ type: "LANGUAGE", payload: survey.defaultLanguage });
        }

        if (survey.translations) {
          try {
            var surveyTranslations = convertTranslationsSchema(
              survey.translations
            );

            var phonicTranslations = localize.getTranslations(); // Loaded from JSON file.

            for (const t in surveyTranslations) {
              if (t in phonicTranslations) {
                phonicTranslations[t] = {
                  ...phonicTranslations[t],
                  ...surveyTranslations[t],
                };
              } else {
                phonicTranslations[t] = surveyTranslations[t];
              }
            }
            localize.loadTranslations(phonicTranslations);
          } catch (e) {
            console.error(e);
          }
        }

        if (survey.conditions) {
          const newConditions = {};
          // Negates adding repeated query strings to URL, newConditions will contain key/vals that are unique from URL and BE
          for (let cond in survey.conditions) {
            if (!parseQueryString(window.location.search)[cond])
              newConditions[cond] = survey.conditions[cond];
          }
          if (Object.keys(newConditions).length > 0)
            window.history.replaceState(
              {},
              "",
              addQueryParams(`${window.location}`, newConditions)
            );
          dispatch({
            type: "UPDATE_CONDITION",
            payload: {
              ...survey.conditions,
              ...parseQueryString(window.location.search),
            },
          }); // Prioritizes current url over what comes from the backend
        }

        dispatchEventSurveyLoaded();
        dispatch({ type: "GET_SURVEY", payload: survey });

        if (
          getState().conditions.page &&
          getState().conditions.page !== "undefined"
        ) {
          var page = parseInt(getState().conditions.page);
          if (
            Number.isInteger(page) &&
            page > -1 &&
            getState().survey.questions &&
            page < getState().survey.questions.length
          ) {
            while (getState().step !== steps.QUESTION) {
              dispatch(nextStep());
            }
          }

          dispatch(skipToQuestionIdx(page, false));
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: "SURVEY_ERROR",
          payload: error.message,
        });
      });
  };
}

function makeid(length) {
  var result = "";
  var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export function generateSessionId() {
  // TODO: store this in a cookie so the user can close and re-open the app without losing progress.
  const sessionId = makeid(20);
  window.PhonicProps.sessionId = sessionId;
  return (dispatch) => {
    return dispatch({
      type: "SESSION_ID",
      payload: sessionId,
    });
  };
}

export function updateRequestStatePending(key) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_REQUEST_STATES",
      payload: { [key]: "ATTEMPTED" },
    });
  };
}

export function updateRequestStateSuccess(key) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_REQUEST_STATES",
      payload: { [key]: "SUCCESS" },
    });
  };
}

export function updateRequestStateFailed(key) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_REQUEST_STATES",
      payload: { [key]: "ERROR" },
    });
  };
}

export function nextQuestion(popQuestionStack) {
  return (dispatch) => {
    window.scrollTo(0, 0);
    dispatch({ type: "NEXT_QUESTION", popQuestionStack: popQuestionStack });
  };
}

export function nextStep() {
  return (dispatch, getState) => {
    if (
      isEmbed() &&
      (getState().step + 1 === steps.FINISH ||
        getState().step + 1 === steps.SCREEN_OUT)
    ) {
      window.parent.postMessage(
        { type: "ON_SURVEY_END", surveyId: getState().survey._id },
        "*"
      );
    }
    window.scrollTo(0, 0);
    dispatch({ type: "NEXT_STEP" });
  };
}
export function prevStep() {
  return (dispatch) => {
    window.scrollTo(0, 0);
    dispatch({ type: "PREV_STEP" });
  };
}
export function screenOut() {
  return (dispatch) => {
    window.scrollTo(0, 0);
    dispatch({ type: "SCREEN_OUT" });
  };
}

export function skipToQuestionIdx(idx, popQuestionStack) {
  return (dispatch) => {
    window.scrollTo(0, 0);
    dispatch({
      type: "SKIP_TO_QUESTION",
      payload: idx,
      popQuestionStack: popQuestionStack,
    });
  };
}

export function addDemographics(data) {
  return (dispatch) => {
    dispatch({
      type: "ADD_DEMOGRAPHICS",
      payload: { ...data },
    });
  };
}

export function postSessionData(surveyId, sessionId, data) {
  return (dispatch, getState) => {
    if (getState().survey.state === "DRAFT" || getState().preview) {
      return;
    }
    return fetch(`${BASE_URL}/${surveyId}/${sessionId}/demographics`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/json",
      },
    })
      .then(() => {})
      .catch((e) => {
        console.error(e);
      });
  };
}

export const postDemographics = (
  surveyId,
  sessionId,
  successCallback,
  confirmContinueCallback
) => {
  track("Posted Response Demographics", {
    surveyId: surveyId,
  });
  return (dispatch, getState) => {
    if (getState().survey.state === "DRAFT" || getState().preview) {
      return;
    }
    dispatch(updateRequestStatePending("Demographics"));
    return (
      publicIp
        .v4()
        .then((ip) => {
          return fetch(`https://ipapi.co/${ip}/json/`).then((response) =>
            response.json()
          );
        })
        // If fetch from publicIp or IP API fails, return placeholder values
        .catch(() => ({ country_name: "Unknown", region: "Unknown" }))
        .then((json) => {
          // Remove keys with value ""
          const data = Object.entries(getState().demographics).reduce(
            (o, [k, v]) => (v === "" ? o : { ...o, [k]: v }),
            {}
          );
          data.country = json.country_name;
          data.region = json.region;
          data.device = getDeviceType();
          data.sessionDurationMs = getState().sessionMs;
          data.url = window.location.href;
          fetch(`${BASE_URL}/${surveyId}/${sessionId}/demographics`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
              "Content-Type": "application/json",
            },
          })
            .then((response) => {
              dispatch({ type: "RESET_SESSION_TIME" });
              return response.json();
            })
            .then((json) => {
              dispatch(updateRequestStateSuccess("Demographics"));
              if (json.message === "MERGE_SESSION_IDS" && json.sessionId) {
                if (confirmContinueCallback) {
                  confirmContinueCallback();
                }
                return dispatch({
                  type: "SESSION_ID",
                  payload: json.sessionId,
                });
              } else {
                dispatch({
                  type: "ADD_DEMOGRAPHICS",
                  payload: { ...data },
                });
                if (successCallback) successCallback();
              }
            })
            .catch((e) => {
              dispatch(updateRequestStateFailed("Demographics"));
              console.log(e);
              // Swallow error from posting demographics
            });
        })
    );
  };
};

function skipTo(skipOption, dispatch, getState, popQuestionStack) {
  if (skipOption !== null) {
    if (skipOption === -2) {
      return dispatch(screenOut());
    } else if (skipOption === -1) {
      return dispatch(nextStep()); // Skip to end of survey.
    } else if (skipOption === 0) {
      if (getState().questionIdx >= getState().survey.questions.length - 1) {
        return dispatch(nextStep());
      } else {
        return dispatch(nextQuestion(popQuestionStack));
      }
    } else {
      // Find the index of the skipOption questionId. If it's not found, just go to the next question/step.
      var newQuestionIdx = getState().survey.questions.findIndex((q) => {
        return q.questionId === skipOption;
      });
      if (newQuestionIdx !== -1) {
        dispatch(skipToQuestionIdx(newQuestionIdx, popQuestionStack));
        return;
      }
    }
  }

  // Shouldn't fall through here (questionId doesn't exist in the question list),
  // but in case we do this just treats skip as a normal quesiton.
  if (getState().questionIdx >= getState().survey.questions.length - 1) {
    dispatch(nextStep());
  } else {
    dispatch(nextQuestion(false));
  }
}

export function runLogicBeforeQuestion() {
  return (dispatch, getState) => {
    if (parseInt(getState().conditions.page) !== getState().questionIdx)
      runLogic(dispatch, getState, "BEFORE", null);
  };
}

/*
Returns true or false depending on if a skip has been made.
*/
function runLogic(dispatch, getState, order /* BEFORE or AFTER*/, data) {
  var question = getState().survey.questions[getState().questionIdx];
  if (!question.logic || !question.logic.length) return false;
  var logic = question.logic.filter((l) => l.execute === order);
  for (let i in logic) {
    // 1. Get the subject
    var subject = [];
    if (logic[i].subject === "ANSWER") {
      let key = question.type.split("_").pop(); // AUDIO_SLIDER, SLIDER => "SLIDER"
      if (data) subject = key in data ? data[key] : null;
      if (Array.isArray(subject)) {
        subject = subject
          .map((el, idx) => (el ? question.options[idx] : null))
          .filter((el) => el !== null); // Convert list of bools to list of values
      } else {
        subject = [subject];
      }
    } else if (logic[i].subject === "CONDITION") {
      subject = [getState().conditions[logic[i].conditionSubject]];
    }

    // 2. Check the subject against the keyword using the operator
    let executeLogic = false;
    for (let j in subject) {
      switch (logic[i].operator) {
        case "IS":
          if (String(subject[j]).trim() === String(logic[i].keyword).trim())
            executeLogic = true;
          break;
        case "IS NOT":
          if (String(subject[j]).trim() !== String(logic[i].keyword).trim())
            executeLogic = true;
          break;
        case "CONTAINS":
        case "DOES NOT CONTAIN":
          if (
            subject[j] &&
            subject[j].includes(String(logic[i].keyword).trim())
          )
            executeLogic = true;
          break;
        default:
          console.error(`Unsupported logic operator: ${logic[i].operator}`);
          break;
      }
    }
    if (logic[i].operator === "DOES NOT CONTAIN") {
      executeLogic = !executeLogic;
    }

    // 3. Do the action on the operand.
    if (executeLogic) {
      switch (logic[i].action) {
        case "ASSIGN CONDITION":
          if (logic[i].operand === "lang") {
            localize.setLocale(logic[i].conditionAssign);
            dispatch({ type: "LANGUAGE", payload: logic[i].conditionAssign });
          }
          dispatch({
            type: "UPDATE_CONDITION",
            payload: { [logic[i].operand]: logic[i].conditionAssign },
          });
          break;
        case "REMOVE CONDITION":
          dispatch({
            type: "UPDATE_CONDITION",
            payload: { [logic[i].operand]: null },
          });
          break;
        case "SKIP TO":
          skipTo(logic[i].operand, dispatch, getState, order === "BEFORE");
          return true; // If a skip occurs we want to stop all other skip logic to prevent multiple jumps.
        case "SET LANGUAGE":
          var newLang = convertLangCodeToISO639(logic[i].conditionAssign);
          localize.setLocale(newLang);
          dispatch({ type: "LANGUAGE", payload: newLang });
          dispatch({
            type: "UPDATE_CONDITION",
            payload: { lang: logic[i].conditionAssign },
          });
          break;
        default:
          console.error(`Unsupported logic action: ${logic[i].action}`);
          break;
      }
    }
  }
  return false;
}

function goToNextStep(dispatch, getState, data) {
  // Run survey logic that runs at the end of the question.
  let question = getState().survey.questions[getState().questionIdx];
  let didSkip = runLogic(dispatch, getState, "AFTER", data);

  // LEGACY Skip Logic: For now we run this after the existing skip logic if it hasn't already proceeded to the next step.
  // Determine if we should need to skip to another question, or just go to the next step.
  // Must be an exclusive selection to work.
  // Only runs if no other skip logic is run to avoid conflicts.
  if (!didSkip) {
    if (
      !question.exclusive ||
      !data ||
      !("SELECTION" in data || "DROPDOWN" in data)
    ) {
      if (getState().questionIdx >= getState().survey.questions.length - 1) {
        return dispatch(nextStep());
      } else {
        return dispatch(nextQuestion(false));
      }
    }
    const questionData = data["SELECTION"] || data["DROPDOWN"];
    var selectedIndex = questionData.indexOf(true);
    var skipOption = null;
    if (question.optionProps)
      skipOption = question.optionProps[selectedIndex].skip;

    skipTo(skipOption, dispatch, getState, false);
  }
}

const THIRTY_TWO_MB_IN_BYTES = 32000000;
export function postResponse(surveyId, questionId, type, data, duration) {
  track("Posted Survey Response", {
    surveyId: surveyId,
    questionId: questionId,
    source: "PHONIC_SURVEY",
  });
  if (isEmbed()) {
    window.parent.postMessage({ type: "ON_QUESTION_SUBMIT", surveyId }, "*");
  }
  return async (dispatch, getState) => {
    if (
      getState().survey.state === "DRAFT" ||
      getState().preview ||
      (getState().survey.questions[getState().questionIdx].optional &&
        getState().survey.questions[getState().questionIdx].type !==
          "DISPLAY" &&
        _.isEmpty(
          data
        )) /* Optional questions with empty data can be skipped without posting. */
    ) {
      goToNextStep(dispatch, getState, data);
      return;
    }
    dispatch({
      type: "QUESTION_STATE",
      payload: {
        requestState: "POST_ATTEMPTED",
        postedData: data,
      },
    });

    var fd = new FormData();
    fd.append("type", type);
    fd.append("url", window.location.href);
    if (getState().conditions.lang)
      fd.append(
        "transcriptionLanguage",
        mapShortCodeToDialect(getState().conditions.lang)
      );

    if (getState().demographics)
      fd.append("demographics", JSON.stringify(getState().demographics));

    let keys = Object.keys(data);
    // Don't upload video if there is backup text
    if (keys.indexOf("BACKUP_TEXT") !== -1) {
      keys = keys.filter((key) => key !== "VIDEO");
    }

    for (const i in keys) {
      const key = keys[i];
      // File uploads aren't stringified, but objects are.
      if (key === "AUDIO" && data["AUDIO"].size < THIRTY_TWO_MB_IN_BYTES) {
        fd.append(key, data[key]);
      } else if (
        key === "VIDEO" ||
        key === "SCREEN" ||
        key === "FILE" ||
        key === "AUDIO"
      ) {
        dispatch({ type: "QUESTION_STATE", payload: { requestProgress: 0 } });
        let ext = getVideoFileExtension(data[key].name);
        let type = "";
        if (key !== "AUDIO") {
          type = `_${key.toLowerCase()}`;
        }
        var uploadPath = `surveys/${surveyId}/questions/${questionId}/responses/${
          getState().sessionId
        }/response${type}.${ext}`;
        let successfulS3Upload = true;
        await singlePartUpload(data[key], uploadPath)
          .on("httpUploadProgress", (progress) => {
            dispatch({
              type: "QUESTION_STATE",
              payload: {
                requestProgress: progress.loaded / progress.total,
              },
            });
          })
          .promise()
          .catch((err) => {
            successfulS3Upload = false;
            // dispatch some action to let user know that there was error
            dispatch({
              type: "QUESTION_STATE",
              payload: {
                requestState: "POST_ERROR",
              },
            });
            dispatch(
              showToast(strpLocalize("Check Internet Connection and Try Again"))
            );
          });
        if (!successfulS3Upload) return;

        if (key === "FILE") {
          fd.append("filePath", uploadPath);
        } else if (key === "AUDIO") {
          fd.append("audioPath", uploadPath);
        } else {
          fd.append("videoPath", uploadPath);
        }
      } else {
        fd.append(key, JSON.stringify(data[key]));
      }
    }

    // Add response duration
    fd.append("responseDurationMs", JSON.stringify(duration));

    // Add session ID
    fd.append("sessionId", getState().sessionId);

    return fetch(`${BASE_URL}/${surveyId}/${questionId}`, {
      method: "POST",
      body: fd,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
      },
    })
      .then((response) => {
        if (response.status >= 400 && response.status < 600) {
          throw new Error(response);
        }
        return response.json();
      })
      .then((json) => {
        dispatch({
          type: "QUESTION_STATE",
          payload: { requestState: "POST_SUCCESS" },
        });

        // Process to next step
        goToNextStep(dispatch, getState, data);
      })
      .catch((error) => {
        // TODO: handle this error gracefully.
        dispatch(showToast(strpLocalize("Unable to post response.")));
        console.error(error);
        dispatch({
          type: "QUESTION_STATE",
          payload: { requestState: "POST_ERROR" },
        });
      });
  };
}

export function updateQuestionState(data) {
  return (dispatch) => {
    dispatch({
      type: "QUESTION_STATE",
      payload: data,
    });
  };
}

export function isPreview() {
  return (dispatch, getState) => {
    dispatch({ type: "PREVIEW" });
  };
}

export function updateLanguage(lang) {
  return (dispatch, getState) => {
    dispatch({ type: "LANGUAGE", payload: lang });
  };
}

export function updateCondition(condition) {
  return (dispatch) => {
    dispatch({ type: "UPDATE_CONDITION", payload: condition });
  };
}

export function setSessionTime(duration) {
  return (dispatch) => {
    dispatch({ type: "SET_SESSION_TIME", payload: duration });
  };
}

export function showToast(message) {
  return (dispatch) => dispatch({ type: "SHOW_TOAST", payload: message });
}

export function clearToast() {
  return (dispatch) => dispatch({ type: "CLEAR_TOAST" });
}

export function submitFeedback(feedback, email) {
  track("Posted Survey Feedback");
  return (dispatch, getState) => {
    dispatch({
      type: "FEEDBACK",
      payload: {
        requestState: "POST_ATTEMPTED",
      },
    });
    return fetch(`${BASE_URL}/feedback`, {
      method: "POST",
      body: JSON.stringify({
        type: "SURVEY_ISSUE",
        surveyId: getState().survey._id,
        sessionId: getState().sessionId,
        message: feedback,
        email: email,
        url: window.location.href,
      }),
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status >= 400 && response.status < 600) {
          throw new Error(response);
        }
        return response.json();
      })
      .then((json) => {
        dispatch({
          type: "FEEDBACK",
          payload: { requestState: "POST_SUCCESS" },
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: "FEEDBACK",
          payload: { requestState: "POST_ERROR" },
        });
      });
  };
}
