import React, { Component } from "react";
import Radio from "@material-ui/core/Radio";
import Paper from "@material-ui/core/Paper";
import { strpLocalize } from "../../localization/localize";

class Likert extends Component {
  // TODO: eventually let the labels be set dynamically
  state = { arr: [], options: [], labels: ["1", "2", "3", "4", "5"] };

  componentWillMount() {
    const useCustomLikertOptions =
      this.props.question.useCustomLikertOptions &&
      this.props.question.customLikertOptions &&
      this.props.question.customLikertOptions.length;
    const labels = useCustomLikertOptions
      ? this.props.question.customLikertOptions
      : this.state.labels;
    this.setState(
      { options: this.props.question.options, labels },
      this.populateArr
    );
  }

  populateArr = () => {
    // Fill the select arr with false values (indicating no selection), or populate from the saved state data arr if provided.
    var arr = new Array(this.props.question.options.length).fill(false);
    if (this.props.data) {
      for (let i = 0; i < arr.length; i++) {
        arr[i] = this.props.data[i];
      }
    }
    this.setState({
      arr: arr,
    });
  };

  handleChange = (rowIdx, colIdx, label, event) => {
    this.setState(
      {
        arr: [
          ...this.state.arr.slice(0, rowIdx),
          label,
          ...this.state.arr.slice(rowIdx + 1),
        ],
      },
      () => {
        this.props.onSubmit(this.state.arr);
      }
    );
  };

  render() {
    return (
      <Paper className="likert-paper">
        <table>
          <thead>
            <tr>
              <th />
              {this.state.labels.map((l) => {
                return <th>{strpLocalize(l)}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {this.state.options.map((option, i) => {
              return (
                <tr>
                  <th>{strpLocalize(option)}</th>
                  {this.state.labels.map((label, j) => {
                    return (
                      <td>
                        <Radio
                          key={`likert-radio-${i}-${j}`}
                          checked={this.state.arr[i] === label}
                          onChange={this.handleChange.bind(this, i, j, label)}
                          value={i}
                          color="primary"
                          inputProps={{ "aria-label": "primary" }}
                        />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Paper>
    );
  }
}

export default Likert;
