import React, { Component } from "react";
import { strpLocalize } from "../../localization/localize";

class File extends Component {
  state = { fileName: "" };
  handleChange = (event) => {
    let file = event.target.files[0];
    if (file) {
      let imgPreview = URL.createObjectURL(file);
      this.setState({
        fileName: file.name,
        imgPreview: imgPreview,
      });
      this.props.onSubmit(file);
    }
  };

  getFileName = () => {
    if (this.state.fileName) return this.state.fileName;
    if (this.props.data && this.props.data.name) return this.props.data.name;
    return null;
  };

  promptUpload = (e) => {
    e.preventDefault();
    this.input.click();
  };

  render() {
    return (
      <div style={{ position: "relative" }}>
        {this.state.imgPreview && (
          <img
            className="file-upload-img-preview"
            src={this.state.imgPreview}
          />
        )}
        <div
          style={{
            marginTop: 25,
            marginBottom: 30,
            borderStyle: "dashed",
            borderWidth: 2,
            borderColor: "#3A71DC",
            borderRadius: 6,
          }}
        >
          <form method="post" action="#" id="#">
            <div className="form-group files">
              <button className="upload-button" onClick={this.promptUpload}>
                {strpLocalize("Choose File")}
              </button>
              <input
                ref={(input) => (this.input = input)}
                type="file"
                label="files"
                className="form-control"
                onChange={this.handleChange.bind(this)}
              />
            </div>
          </form>
          <div className="subtitle">{this.getFileName()}</div>
        </div>
      </div>
    );
  }
}

export default File;
