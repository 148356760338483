import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { ScaleLoader } from "react-spinners";

class Loading extends Component {
  render() {
    return (
      <div className="Loading">
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <div style={{ marginTop: "45vh" }}>
            <ScaleLoader
              style={{ margin: "0 auto", width: 50 }}
              size={"30px"}
              color={"#2d8677"}
              loading={true}
            />
          </div>
        </Grid>
      </div>
    );
  }
}

export default withRouter(Loading);
