import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";

class Text extends Component {
  handleChange = (event) => {
    this.props.onSubmit(event.target.value);
  };
  render() {
    return (
      <Paper>
        <TextField
          id="outlined-secondary"
          placeholder="Response"
          variant="outlined"
          color="primary"
          multiline
          fullWidth
          rows="4"
          onChange={this.handleChange.bind(this)}
          disabled={this.props.disabled}
          defaultValue={this.props.data}
        />
      </Paper>
    );
  }
}

export default Text;
