import React, { Component } from "react";
import MainButton from "../components/Buttons/MainButton";
import PrevButton from "../components/Buttons/PrevButton";
import { Grid, Typography } from "@material-ui/core";
import { strpLocalize } from "../localization/localize";
import { replaceLineBreaksHtml } from "../utils/utils";

function getSummaryStyle(text, align) {
  if (!text) return {};
  var size = 20;

  if (text.length > 100) {
    size = 16;
    if (!align) align = "left";
  }

  return { fontSize: size, textAlign: align };
}

// function separateParagraphs(summary, align) {
//   return summary.split("\n").map((par, idx) => {
//     return (
//       <h2
//         className="subtitle"
//         key={`summary-paragraph-${idx}`}
//         style={{
//           ...getSummaryStyle(summary, align),
//           lineHeight: 1.4,
//           marginTop: 8,
//         }}
//       >
//         {strpLocalize(par)}
//         <br />
//       </h2>
//     );
//   });
// }

function separateParagraphs(text) {
  if (!text) return null;
  text = replaceLineBreaksHtml(text);

  // Adjusting the font size:
  // Font size is in em, 1em being the "default" size. Formula is text.length < a ? 1.0 - (1-c)/(a-b)*(text.length-b) : c
  // where a is the length at which the text stops shrinking, b is the length at which the text starts shrinking, and
  // c is the minimum relative size for very long titles/subtitles. When length is < b or > a, font size is constant,
  // and font size decreases linearly from 1.0 to c when b < length < a
  return (
    <div
      className="subtitle"
      style={{
        fontSize:
          "" +
          (text.length < 110 ? 1.0 - 0.00375 * (text.length - 30) : 0.9) +
          "em",
      }}
      dangerouslySetInnerHTML={{ __html: strpLocalize(text) }}
    />
  );
}

class Summary extends Component {
  render() {
    return (
      <Grid container direction="row">
        <Grid item style={{ width: "100%" }}>
          <Typography variant="h2" className="title">
            {strpLocalize("Instructions")}
          </Typography>
          {strpLocalize(
            separateParagraphs(
              this.props.survey.summary,
              this.props.survey.align
            )
          )}
          <br />
        </Grid>
        <Grid item xs={12}>
          <PrevButton onClick={this.props.prevStep} />
          <MainButton onClick={this.props.nextStep} className="empty-skip">
            <span>{strpLocalize("Next")}</span>
          </MainButton>
        </Grid>
      </Grid>
    );
  }
}

export default Summary;
