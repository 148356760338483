//renamed class to SliderType because of naming conflict with MUI Slider

import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { strpLocalize } from "../../localization/localize";

const StyledSlider = withStyles({
  root: {
    color: "#C4C4C4",
    height: 8,
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: "#494949",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &:active": {
      boxShadow: "inherit",
    },
  },
  valueLabel: {
    left: "calc(-50% + 12px)",
    color: "#494949",
  },
  track: {
    height: 10,
    borderRadius: 4,
    color: "#494949",
  },
  rail: {
    height: 10,
    borderRadius: 4,
  },
})(Slider);

class SliderType extends Component {
  getLowerLabel = () => {
    if (this.props.question.bounds && this.props.question.bounds.lowerLabel) {
      return strpLocalize(this.props.question.bounds.lowerLabel);
    }
    return "";
  };

  getUpperLabel = () => {
    if (this.props.question.bounds && this.props.question.bounds.upperLabel) {
      return strpLocalize(this.props.question.bounds.upperLabel);
    }
    return "";
  };

  getMin = () => {
    if (this.props.question.bounds && this.props.question.bounds.lower) {
      return parseInt(this.props.question.bounds.lower);
    }
    return 0;
  };

  getMax = () => {
    if (this.props.question.bounds && this.props.question.bounds.upper) {
      return parseInt(this.props.question.bounds.upper);
    }
    return 10;
  };

  getInterval = () => {
    if (this.props.question.bounds && this.props.question.bounds.interval) {
      return Number(this.props.question.bounds.interval);
    }
    return 1;
  };

  handleChange = (event, value) => {
    this.props.onSubmit(value);
  };

  render() {
    return (
      <Paper
        style={{
          maxWidth: 700,
          margin: "auto",
          paddingTop: 20,
          paddingBottom: 15,
          paddingLeft: 40,
          paddingRight: 40,
        }}
      >
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <StyledSlider
              aria-labelledby="discrete-slider-small-steps"
              step={this.getInterval()}
              min={this.getMin()}
              max={this.getMax()}
              valueLabelDisplay="auto"
              onChange={this.handleChange.bind(this)}
              disabled={this.props.disabled}
              value={this.props.data || this.getMin()}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" align="left">
              {this.getLowerLabel()}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" align="right">
              {this.getUpperLabel()}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default SliderType;
