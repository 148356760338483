import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { strpLocalize } from "../../localization/localize";
import { isEmbed } from "../../utils/utils";
import ProgressBar from "../Progress/ProgressBar";

class Header extends Component {
  getHeaderContent() {
    if (isEmbed()) return null;
    let light = this.props.theme === "light";
    return (
      <>
        {this.props.img ? (
          <img
            className={"logo-navbar"}
            src={this.props.img}
            alt="powered by phonic"
          ></img>
        ) : isEmbed() ? null : (
          <a href="https://phonic.ai">
            <img
              className={"phonic-logo-navbar"}
              src={
                light
                  ? require("../../assets/img/powered-by-phonic-white.png")
                  : require("../../assets/img/powered-by-phonic-black.png")
              }
              alt="powered by phonic"
            ></img>
          </a>
        )}
      </>
    );
  }
  getBoxText() {
    if (isEmbed()) return null;
    if (this.props.survey && this.props.survey.state === "DRAFT")
      return (
        <div className="app-header-right">
          <div className="draft-text">{strpLocalize("DRAFT")}</div>
        </div>
      );
    else if (this.props.preview)
      return (
        <div className="app-header-right">
          <span className="preview-text">{strpLocalize("PREVIEW")}</span>
        </div>
      );
    return null;
  }
  render() {
    return isEmbed() ? (
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8}>
          <ProgressBar value={this.props.progressValue} />
        </Grid>
      </Grid>
    ) : (
      <Grid container alignItems="center">
        <Grid item xs={3}>
          <div className="app-header-left">{this.getHeaderContent()}</div>
        </Grid>
        <Grid container item xs={6} justifyContent="center" alignItems="center">
          {this.props.progressValue !== undefined && (
            <ProgressBar value={this.props.progressValue} />
          )}
        </Grid>
        <Grid item xs={3}>
          {this.getBoxText()}
        </Grid>
      </Grid>
    );
  }
}

export default Header;
