/**
 * Basic Event System
 * Survey builders can hook into these events for custom integrations.
 */

export function dispatchEventSurveyLoaded() {
  const e = new CustomEvent("surveyloaded");
  document.dispatchEvent(e);
}

export function dispatchEventSurveyStarted() {
  const e = new CustomEvent("surveystarted");
  document.dispatchEvent(e);
}

export function dispatchEventSurveyCompleted() {
  const e = new CustomEvent("surveycompleted");
  document.dispatchEvent(e);
}

export function dispatchEventScreenedOut() {
  const e = new CustomEvent("screenedout");
  document.dispatchEvent(e);
}
