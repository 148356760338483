import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";

class Number extends Component {
  handleChange = (event) => {
    this.props.onSubmit(event.target.value);
  };
  render() {
    return (
      <Paper
        style={{
          maxWidth: 200,
          marginTop: 25,
          marginBottom: 30,
        }}
        className={clsx(
          this.props.align === "left" ? "ml-0" : "ml-auto",
          this.props.align === "right" ? "mr-0" : "mr-auto"
        )}
      >
        <TextField
          id="outlined-secondary"
          variant="outlined"
          color="primary"
          fullWidth
          onChange={this.handleChange.bind(this)}
          disabled={this.props.disabled}
          defaultValue={this.props.data}
          placeholder=""
        ></TextField>
      </Paper>
    );
  }
}

export default Number;
