import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import AudioType from "./AudioType";
import Video from "./Video";
import Screen from "./Screen";
import Text from "./Text";
import Number from "./Number";
import Date from "./Date";
import SliderType from "./SliderType";
import File from "./File";
import CheckboxSelection from "./CheckboxSelection";
import RadioSelection from "./RadioSelection";
import Dropdown from "./Dropdown";
import Likert from "./Likert";
import Ranking from "./Ranking";
import { connect } from "react-redux";
import Code from "./Code";
import Stimuli from "../../components/Stimuli";
import {
  strpLocalize,
  replaceStrOrLocalize,
} from "../../localization/localize";
import { replaceLineBreaksHtml } from "../../utils/utils";

const mapStateToProps = (state) => {
  return {
    survey: state.survey,
    questionIdx: state.questionIdx,
    question: state.question,
    questionState: state.questionState[state.questionIdx],
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

function separateParagraphs(text) {
  if (!text) return null;
  text = replaceLineBreaksHtml(text);

  // Adjusting the font size:
  // Font size is in em, 1em being the "default" size. Formula is text.length < a ? 1.0 - (1-c)/(a-b)*(text.length-b) : c
  // where a is the length at which the text stops shrinking, b is the length at which the text starts shrinking, and
  // c is the minimum relative size for very long titles/subtitles. When length is < b or > a, font size is constant,
  // and font size decreases linearly from 1.0 to c when b < length < a
  return (
    <div
      style={{
        fontSize:
          "" +
          (text.length < 110 ? 1.0 - 0.00375 * (text.length - 30) : 0.9) +
          "em",
      }}
      dangerouslySetInnerHTML={{ __html: strpLocalize(text) }}
    />
  );
}

// This wrapper adds backup text functionality to Audio and Video questions
class BackupTextWrapper extends Component {
  state = { mode: this.props.question.type.split("_")[0] };
  render() {
    const type = this.props.question.type.split("_")[0]; // AUDIO_SLIDER => AUDIO
    return (
      <>
        {this.state.mode === "AUDIO" && <AudioType {...this.props} />}
        {this.state.mode === "VIDEO" && <Video {...this.props} />}
        {this.state.mode === "TEXT" && (
          <Text
            question={this.props.question}
            onSubmit={this.props.dataChange.bind(this, "BACKUP_TEXT")}
            data={this.props.data}
          />
        )}
        {this.props.question.allowBackupText && (
          <button
            className="audio-toggle-mode subtitle"
            onClick={() =>
              this.setState({
                mode: this.state.mode === "TEXT" ? type : "TEXT",
              })
            }
          >
            {this.state.mode === "TEXT"
              ? strpLocalize(`Respond with ${type.toLowerCase()}`)
              : strpLocalize("Or click here to answer with text")}
          </button>
        )}
      </>
    );
  }
}

class QuestionWrapper extends Component {
  dataChange = (type, data) => {
    this.props.onSubmit(type, data);
  };

  getSelectionView() {
    if (!this.props.question) return null;

    if (this.props.question.exclusive) {
      return (
        <RadioSelection
          key={`RADIO-${this.props.questionIdx}`}
          question={this.props.question}
          onSubmit={this.dataChange.bind(this, "SELECTION")}
          data={this.props.questionState.data["SELECTION"]}
        />
      );
    } else {
      return (
        <CheckboxSelection
          key={`CHECKBOX-${this.props.questionIdx}`}
          question={this.props.question}
          onSubmit={this.dataChange.bind(this, "SELECTION")}
          data={this.props.questionState.data["SELECTION"]}
        />
      );
    }
  }

  getTranslatedTitle(key /* either text or subText */) {
    if (!this.props.question.translations)
      return separateParagraphs(strpLocalize(this.props.question[key]));

    let translation = replaceStrOrLocalize(
      this.props.question[key],
      this.props.question.translations[key],
      this.props.language
    );

    return separateParagraphs(translation);
  }

  getTitle = () => {
    if (!this.props.question) return null;
    return this.props.question.text ? this.getTranslatedTitle("text") : "";
  };

  getSubTitle = () => {
    if (!this.props.question) return null;
    if (this.props.question.subText) return this.getTranslatedTitle("subText");

    switch (this.props.question.type) {
      case "DISPLAY":
        return (
          <span style={{ marginBottom: 12 }}>
            {strpLocalize(this.props.question.subText)}
          </span>
        );

      case "AUDIO":
        return strpLocalize("Audio Response");

      case "VIDEO":
        return strpLocalize("Video Response");

      case "SCREEN":
        return strpLocalize("Screen Recording");

      case "TEXT":
        return strpLocalize("Text Response");

      case "NUMBER":
        return strpLocalize("Enter a Number");
      case "DATE":
        return strpLocalize("Enter a Date");
      case "SLIDER":
        return strpLocalize("Drag the Slider");
      case "DROPDOWN":
        return strpLocalize("Select One");
      case "SELECTION":
        if (this.props.question.exclusive) return strpLocalize("Select One");
        return strpLocalize("Check All That Apply");

      case "AUDIO_SELECTION":
        if (this.props.question.exclusive)
          return strpLocalize(
            "Select one and use the microphone to explain your choice."
          );
        return strpLocalize(
          "Check all that apply and use the microphone to explain your choice."
        );

      case "AUDIO_TEXT":
        return strpLocalize(
          "Type a response and use the microphone to explain your choice."
        );

      case "AUDIO_SLIDER":
        return strpLocalize(
          "Drag the slider and use the microphone to explain your choice."
        );

      case "THINKALOUD":
        return strpLocalize(
          "The microphone is already recording. Speak your thoughts freely."
        );

      case "FILE":
        return strpLocalize("File Upload");

      case "LIKERT":
        return strpLocalize("Rate the following items.");

      case "RANKING":
        return strpLocalize("Rank the following items.");

      case "AUDIO_RANKING":
        return strpLocalize(
          "Rank the following items and use the microphone to explain your choice."
        );

      default:
        return;
    }
  };

  getInput = () => {
    if (!this.props.question) return null;

    switch (this.props.question.type) {
      case "DISPLAY":
        return null;
      case "AUDIO":
        return (
          <BackupTextWrapper
            key={`AUDIO-${this.props.questionIdx}`}
            question={this.props.question}
            onSubmit={this.dataChange.bind(this, "AUDIO")}
            questionIdx={this.props.questionIdx}
            toggleAudioRecording={this.props.toggleAudioRecording}
            audioParams={this.props.audioParams}
            dataChange={this.dataChange.bind(this)}
            data={this.props.questionState.data["BACKUP_TEXT"]}
            file={this.props.questionState.data["AUDIO"]}
            theme={this.props.survey.background}
            align={this.props.survey.align}
          />
        );
      case "VIDEO":
        return (
          <BackupTextWrapper
            key={`VIDEO-${this.props.questionIdx}`}
            question={this.props.question}
            onSubmit={this.dataChange.bind(this, "VIDEO")}
            questionIdx={this.props.questionIdx}
            videoParams={this.props.videoParams}
            dataChange={this.dataChange.bind(this)}
            data={this.props.questionState.data["BACKUP_TEXT"]}
            align={this.props.survey.align}
          />
        );
      case "SCREEN":
        return (
          <Screen
            key={`SCREEN-${this.props.questionIdx}`}
            question={this.props.question}
            onSubmit={this.dataChange.bind(this, "SCREEN")}
            questionIdx={this.props.questionIdx}
            videoParams={this.props.videoParams}
            dataChange={this.dataChange.bind(this)}
            data={this.props.questionState.data["SCREEN"]}
            align={this.props.survey.align}
          />
        );
      case "TEXT":
        return (
          <Text
            key={`TEXT-${this.props.questionIdx}`}
            question={this.props.question}
            onSubmit={this.dataChange.bind(this, "TEXT")}
            data={this.props.questionState.data["TEXT"]}
          />
        );
      case "NUMBER":
        return (
          <Number
            key={`NUMBER-${this.props.questionIdx}`}
            question={this.props.question}
            onSubmit={this.dataChange.bind(this, "NUMBER")}
            data={this.props.questionState.data["NUMBER"]}
            align={this.props.survey.align}
          />
        );
      case "DATE":
        return (
          <Date
            key={`DATE-${this.props.questionIdx}`}
            onSubmit={this.dataChange.bind(this, "DATE")}
            data={this.props.questionState.data["DATE"]}
            align={this.props.survey.align}
          />
        );
      case "SLIDER":
        return (
          <SliderType
            key={`SLIDER-${this.props.questionIdx}`}
            question={this.props.question}
            onSubmit={this.dataChange.bind(this, "SLIDER")}
            data={this.props.questionState.data["SLIDER"]}
          />
        );
      case "FILE":
        return (
          <File
            key={`FILE-${this.props.questionIdx}`}
            question={this.props.question}
            onSubmit={this.dataChange.bind(this, "FILE")}
            data={this.props.questionState.data["FILE"]}
          />
        );
      case "DROPDOWN":
        return (
          <Dropdown
            key={`DROPDOWN-${this.props.questionIdx}`}
            question={this.props.question}
            onSubmit={this.dataChange.bind(this, "DROPDOWN")}
            data={this.props.questionState.data["DROPDOWN"]}
          />
        );
      case "SELECTION":
        return this.getSelectionView();
      case "LIKERT":
        return (
          <Likert
            key={`LIKERT-${this.props.questionIdx}`}
            question={this.props.question}
            onSubmit={this.dataChange.bind(this, "LIKERT")}
            data={this.props.questionState.data["LIKERT"]}
          />
        );
      case "RANKING":
        return (
          <Ranking
            key={`RANKING-${this.props.questionIdx}`}
            question={this.props.question}
            onSubmit={this.dataChange.bind(this, "RANKING")}
            data={this.props.questionState.data["RANKING"]}
          />
        );
      case "AUDIO_SELECTION":
        return (
          <div>
            {this.props.question.recorderPosition === "bottom" &&
              this.getSelectionView()}
            <BackupTextWrapper
              key={`AUDIO_SELECTION-${this.props.questionIdx}`}
              question={this.props.question}
              onSubmit={this.dataChange.bind(this, "AUDIO")}
              questionIdx={this.props.questionIdx}
              toggleAudioRecording={this.props.toggleAudioRecording}
              audioParams={this.props.audioParams}
              dataChange={this.dataChange.bind(this)}
              data={this.props.questionState.data["BACKUP_TEXT"]}
              file={this.props.questionState.data["AUDIO"]}
              theme={this.props.survey.background}
              align={this.props.survey.align}
            />
            {this.props.question.recorderPosition !== "bottom" &&
              this.getSelectionView()}
          </div>
        );
      case "AUDIO_TEXT":
        return (
          <div>
            <BackupTextWrapper
              key={`AUDIO_TEXT-${this.props.questionIdx}`}
              question={this.props.question}
              onSubmit={this.dataChange.bind(this, "AUDIO")}
              questionIdx={this.props.questionIdx}
              toggleAudioRecording={this.props.toggleAudioRecording}
              audioParams={this.props.audioParams}
              dataChange={this.dataChange.bind(this)}
              data={this.props.questionState.data["BACKUP_TEXT"]}
              file={this.props.questionState.data["AUDIO"]}
              theme={this.props.survey.background}
              align={this.props.survey.align}
            />
            <Text
              key={`TEXT-${this.props.questionIdx}`}
              question={this.props.question}
              onSubmit={this.dataChange.bind(this, "TEXT")}
              data={this.props.questionState.data["TEXT"]}
            />
          </div>
        );
      case "AUDIO_SLIDER":
        return (
          <div>
            <BackupTextWrapper
              key={`AUDIO_SLIDER-${this.props.questionIdx}`}
              question={this.props.question}
              onSubmit={this.dataChange.bind(this, "AUDIO")}
              questionIdx={this.props.questionIdx}
              toggleAudioRecording={this.props.toggleAudioRecording}
              audioParams={this.props.audioParams}
              dataChange={this.dataChange.bind(this)}
              data={this.props.questionState.data["BACKUP_TEXT"]}
              file={this.props.questionState.data["AUDIO"]}
              theme={this.props.survey.background}
              align={this.props.survey.align}
            />
            <SliderType
              key={`SLIDER-${this.props.questionIdx}`}
              question={this.props.question}
              onSubmit={this.dataChange.bind(this, "SLIDER")}
              data={this.props.questionState.data["SLIDER"]}
            />
          </div>
        );
      case "THINKALOUD":
        return (
          <div>
            <BackupTextWrapper
              key={`THINKALOUD-${this.props.questionIdx}`}
              thinkAloud={true}
              question={this.props.question}
              onSubmit={this.dataChange.bind(this, "AUDIO")}
              questionIdx={this.props.questionIdx}
              toggleAudioRecording={this.props.toggleAudioRecording}
              audioParams={this.props.audioParams}
              dataChange={this.dataChange.bind(this)}
              data={this.props.questionState.data["BACKUP_TEXT"]}
              file={this.props.questionState.data["AUDIO"]}
              theme={this.props.survey.background}
            />
            <Text
              key={`TEXT-${this.props.questionIdx}`}
              question={this.props.question}
              onSubmit={this.dataChange.bind(this, "TEXT")}
              disabled={this.props.audioParams.isRecording === false}
              data={this.props.questionState.data["TEXT"]}
            />
          </div>
        );
      case "THINKALOUD_CODE":
        return (
          <div>
            <BackupTextWrapper
              key={`THINKALOUD_CODE-${this.props.questionIdx}`}
              thinkAloud={true}
              question={this.props.question}
              onSubmit={this.dataChange.bind(this, "AUDIO")}
              questionIdx={this.props.questionIdx}
              toggleAudioRecording={this.props.toggleAudioRecording}
              audioParams={this.props.audioParams}
              dataChange={this.dataChange.bind(this)}
              data={this.props.questionState.data["BACKUP_TEXT"]}
              file={this.props.questionState.data["AUDIO"]}
              theme={this.props.survey.background}
              align={this.props.survey.align}
            />
            <Code
              key={`CODE-${this.props.questionIdx}`}
              question={this.props.question}
              onSubmit={this.dataChange.bind(this, "TEXT")}
              disabled={this.props.audioParams.isRecording === false}
            />
          </div>
        );
      case "AUDIO_RANKING":
        return (
          <div>
            <BackupTextWrapper
              key={`AUDIO_RANKING-${this.props.questionIdx}`}
              thinkAloud={true}
              question={this.props.question}
              onSubmit={this.dataChange.bind(this, "AUDIO")}
              questionIdx={this.props.questionIdx}
              toggleAudioRecording={this.props.toggleAudioRecording}
              audioParams={this.props.audioParams}
              dataChange={this.dataChange.bind(this)}
              data={this.props.questionState.data["BACKUP_TEXT"]}
              file={this.props.questionState.data["AUDIO"]}
              theme={this.props.survey.background}
            />
            <Ranking
              key={`RANKING-${this.props.questionIdx}`}
              question={this.props.question}
              onSubmit={this.dataChange.bind(this, "RANKING")}
              data={this.props.questionState.data["RANKING"]}
            />
          </div>
        );
      default:
        return (
          <div style={{ margin: 30 }}>
            {strpLocalize("Unsupported Question Type")}
          </div>
        );
    }
  };

  render() {
    return (
      <>
        <Typography variant="h2" className="title" gutterBottom>
          {this.getTitle()}
        </Typography>
        <Typography variant="h3" className="subtitle" gutterBottom>
          {this.getSubTitle()}
        </Typography>
        <div className="pt-1">
          {this.props.question.stimuli && this.props.question.stimuli.type && (
            <Stimuli
              key={`STIMULI-${this.props.questionIdx}`}
              stimuli={this.props.question.stimuli}
            />
          )}
          {this.getInput()}
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionWrapper);
