import React, { Component, createRef } from "react";
import PageVisibility from "react-page-visibility";
import MainButton from "../components/Buttons/MainButton";
import PrevButton from "../components/Buttons/PrevButton";
import MiniProgress from "../components/Progress/MiniProgress";
import StatusBar from "../components/Alerts/StatusBar";
import { connect } from "react-redux";
import {
  postResponse,
  prevStep,
  nextStep,
  nextQuestion,
  updateQuestionState,
  runLogicBeforeQuestion,
} from "../redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import CheckIcon from "@material-ui/icons/Check";
import { withRouter } from "react-router-dom";
import QuestionTypeSelector from "../views/Questions/QuestionTypeSelector";
import ClipLoader from "react-spinners/ClipLoader";
import { Recorder } from "../recorder";
import {
  showDemographics,
  secondsToString,
  dataIsEqual,
  isNumber,
} from "../utils/utils";
import parseISO from "date-fns/parseISO";
import isValid from "date-fns/isValid";
import { strpLocalize } from "../localization/localize";
import clsx from "clsx";
import { trackPage } from "../analytics";

const mapStateToProps = (state) => {
  return {
    survey: state.survey,
    questionState: state.questionState[state.questionIdx],
    questionIdx: state.questionIdx,
    sessionId: state.sessionId,
    question: state.question,
    preview: state.preview,
    audioRecordingFormat: state.audioRecordingFormat,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postResponse: (sId, qId, type, data, ms) =>
      dispatch(postResponse(sId, qId, type, data, ms)),
    prevStep: () => dispatch(prevStep()),
    nextStep: () => dispatch(nextStep()),
    nextQuestion: () => dispatch(nextQuestion()),
    updateQuestionState: (data) => dispatch(updateQuestionState(data)),
    runLogicBeforeQuestion: () => dispatch(runLogicBeforeQuestion()),
  };
};

const MINIMUM_AUDIO_RESPONSE_DURATION_MS = 500;

class Question extends Component {
  state = {
    startTime: null,
    timerRunning: false,
  };

  // Class objects
  recorder = null;

  statusRef = createRef();

  componentWillMount() {
    trackPage("Question", {
      sessionId: this.props.sessionId,
      surveyId: this.props.match.params.surveyId,
    });
    this.recorder = new Recorder(this.props.audioRecordingFormat);
  }

  componentDidMount() {
    this.startTimer();

    // This runs only for BEFORE Q0
    this.props.runLogicBeforeQuestion();

    // Only start the mic if THINKALOUD is the very first question.
    if (this.props.questionIdx === 0) {
      this.toggleIfThinkAloud();
    }

    if (this.props.question.enableCustomScript)
      eval(this.props.question.customScript);

    // Start the countdown timer (assuming at least 1 question has a timer).
    this.countdownTimer = setInterval(() => {
      if (this.props.questionState.countdown > 0)
        this.props.updateQuestionState({
          countdown: this.props.questionState.countdown - 1,
        });
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    // Handles all cases going forwards + backwards except for BEFORE Q0.
    if (prevProps.questionIdx !== this.props.questionIdx) {
      this.props.runLogicBeforeQuestion();
      this.startTimer();
      if (this.props.question.enableCustomScript)
        eval(this.props.question.customScript);
    }

    // Only start the mic if the user is going to the next question, not a previous one.
    if (prevProps.questionIdx === this.props.questionIdx - 1) {
      this.toggleIfThinkAloud();
    }
  }

  componentWillUnmount() {
    // Cancel the countdownTimer if it exists
    if (this.countdownTimer) clearInterval(this.countdownTimer);
  }

  onSubmit = (type, data) => {
    // ex. { AUDIO: File(), SELECTION: [true, false, true] }
    this.props.updateQuestionState({
      data: { ...this.props.questionState.data, [type]: data },
    });
  };

  // Recorder for Audio Questions
  toggleAudioRecording = (submitAfter) => {
    if (this.props.questionState.isRecording) {
      // Stop Recording
      clearInterval(this.micTimer);

      this.recorder
        .stop()
        .then((file) => {
          this.props.updateQuestionState({ isRecording: false });
          if (
            this.recorder.getRecordingDuration() <
            MINIMUM_AUDIO_RESPONSE_DURATION_MS
          ) {
            this.alert(strpLocalize("Your message was too short. Try again."));
            return;
          }
          this.onSubmit("AUDIO", file);
          this.props.updateQuestionState({ somethingRecorded: true });
          if (submitAfter) this.postResponseAndProceed();
        })
        .catch((e) => {
          this.alert(strpLocalize("We could not record your message."));
          console.error(e);
        });
    } else {
      this.recorder
        .start()
        .then(() => {
          this.props.updateQuestionState({
            startTime: Date.now(),
            timeElapsed: 0,
          });

          this.micTimer = setInterval(() => {
            this.props.updateQuestionState({
              timeElapsed: this.props.questionState.timeElapsed + 1,
            });
          }, 1000);

          this.props.updateQuestionState({ isRecording: true });
        })
        .catch((e) => {
          console.error(e);
          this.alert(strpLocalize("Could not start recording."));
        });
    }
  };

  // Recorder for video and screen questions.
  onStartRecordingVideo = () => {
    this.recordingVideo = true;
  };
  onStopRecordingVideo = () => {
    this.recordingVideo = false;
  };

  arraysEqual = (_arr1, _arr2) => {
    if (
      !Array.isArray(_arr1) ||
      !Array.isArray(_arr2) ||
      _arr1.length !== _arr2.length
    )
      return false;
    var arr1 = _arr1.concat().sort();
    var arr2 = _arr2.concat().sort();
    for (var i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  };

  validateText = () => {
    if (this.props.survey.state === "DRAFT" || this.props.preview) {
      return true;
    }

    if (
      !this.props.questionState.data ||
      Object.entries(this.props.questionState.data).length === 0 ||
      this.props.questionState.data["TEXT"] === ""
    ) {
      this.alert(strpLocalize("Please provide a response."));
      return false;
    }
    return true;
  };

  validateResponses = () => {
    // Display questions don't require validation
    if (
      this.props.question.type === "DISPLAY" ||
      this.props.question.optional ||
      this.props.questionState.requestState === "POST_SUCCESS"
    ) {
      return true;
    }
    // Object is empty or null.
    if (
      (!this.props.questionState.data && this.props.questionState.data !== 0) ||
      Object.entries(this.props.questionState.data).length === 0
    ) {
      this.alert(strpLocalize("Please provide a response."));
      return false;
    }
    // Object entries are valid (for ex. text is not empty string)
    for (const key in this.props.questionState.data) {
      if (
        !this.props.questionState.data[key] &&
        this.props.questionState.data[key] !== 0
      ) {
        this.alert(strpLocalize("Please provide a response."));
        return false;
      }
      switch (key) {
        case "AUDIO":
          // TODO: validate the audio recording.
          break;
        case "VIDEO":
        case "SCREEN":
        case "FILE":
          if (
            !this.props.questionState.data[key] ||
            !this.props.questionState.data[key].size
          ) {
            // TODO: validate the video recording.
            return false;
          }
          return true; // No backup text for video right now.

        case "TEXT":
        case "BACKUP_TEXT":
          if (this.props.questionState.data[key] === "") {
            this.alert(strpLocalize("Please provide a response."));
            return false;
          }
          break;
        case "NUMBER":
          if (!isNumber(this.props.questionState.data[key])) {
            this.alert(strpLocalize("Please enter a number."));
            return false;
          }
          break;
        case "DATE":
          if (!isValid(parseISO(this.props.questionState.data[key]))) {
            this.alert(strpLocalize("Please provide a valid date."));
            return false;
          }
          break;
        case "SLIDER":
          break;
        case "DROPDOWN":
        case "SELECTION":
          if (!this.props.question.exclusive) {
            const everyValFalse = (el) => el === false;
            if (this.props.questionState.data[key].every(everyValFalse)) {
              this.alert(strpLocalize("You must select at least one option."));
              return false;
            }
          }
          // TODO: Exclusive selection validation
          break;
        case "LIKERT":
          if (!this.props.questionState.data[key].every((el) => el !== false)) {
            this.alert(strpLocalize("You must select at least one option."));
            return false;
          }
          break;
        default:
          break;
      }
    }

    // Validate that the response types match the question.
    // The first is the usual verification, and the second
    // is if backup text is allowed.
    var REQUIRED_RESPONSE_TYPES = {
      AUDIO: [["AUDIO"], ["BACKUP_TEXT"]],
      VIDEO: [["VIDEO"], ["BACKUP_TEXT"]],
      SCREEN: [["SCREEN"], ["BACKUP_TEXT"]],
      SELECTION: [["SELECTION"], ["SELECTION"]],
      DROPDOWN: [["DROPDOWN"], ["DROPDOWN"]],
      TEXT: [["TEXT"], ["TEXT"]],
      NUMBER: [["NUMBER"], ["NUMBER"]],
      DATE: [["DATE"], ["DATE"]],
      SLIDER: [["SLIDER"], ["SLIDER"]],
      LIKERT: [["LIKERT"], ["LIKERT"]],
      RANKING: [["RANKING"], ["RANKING"]],
      AUDIO_SELECTION: [
        ["AUDIO", "SELECTION"],
        ["BACKUP_TEXT", "SELECTION"],
      ],
      VIDEO_SELECTION: [
        ["VIDEO", "SELECTION"],
        ["BACKUP_TEXT", "SELECTION"],
      ],
      AUDIO_TEXT: [
        ["AUDIO", "TEXT"],
        ["BACKUP_TEXT", "TEXT"],
      ],
      AUDIO_SLIDER: [
        ["AUDIO", "SLIDER"],
        ["BACKUP_TEXT", "SLIDER"],
      ],
      AUDIO_RANKING: [
        ["AUDIO", "RANKING"],
        ["BACKUP_TEXT", "RANKING"],
      ],
      THINKALOUD: [
        ["AUDIO", "TEXT"],
        ["BACKUP_TEXT", "TEXT"],
      ],
      THINKALOUD_CODE: [
        ["AUDIO", "TEXT"],
        ["BACKUP_TEXT", "TEXT"],
      ],
    };
    var qType = this.props.question.type;
    if (
      !this.props.question.allowBackupText &&
      !this.arraysEqual(
        REQUIRED_RESPONSE_TYPES[qType][0],
        Object.keys(this.props.questionState.data)
      )
    ) {
      if (qType.includes("AUDIO"))
        this.alert(
          strpLocalize("Please provide both an audio and other response.")
        );
      if (qType.includes("VIDEO") || qType.includes("SCREEN"))
        this.alert(
          strpLocalize("Please provide both a video and other response.")
        );
      return false;
    }
    if (
      this.props.question.allowBackupText &&
      !REQUIRED_RESPONSE_TYPES[qType][1].every(
        (el) => Object.keys(this.props.questionState.data).indexOf(el) !== -1
      ) &&
      !REQUIRED_RESPONSE_TYPES[qType][0].every(
        (el) => Object.keys(this.props.questionState.data).indexOf(el) !== -1
      )
    ) {
      if (qType.includes("AUDIO"))
        this.alert(
          strpLocalize("Please provide either an audio or text response.")
        );
      if (qType.includes("VIDEO") || qType.includes("SCREEN"))
        this.alert(
          strpLocalize("Please provide either a video or text response.")
        );
      return false;
    }
    return true;
  };

  postResponseAndProceed = async () => {
    // Check the think-aloud case
    if (this.props.question.type === "THINKALOUD" && !this.validateText())
      return;

    // Video & screen recording must be stopped manually.
    if (this.recordingVideo) {
      strpLocalize("Please stop recording first.");
      return;
    }

    // Toggle Audio for all audio cases
    if (this.props.questionState.isRecording) {
      this.toggleAudioRecording(true);
      return;
    }
    // Check all other cases
    if (
      this.props.survey.state === "DRAFT" ||
      this.props.preview ||
      this.validateResponses()
    ) {
      // This prevents questions from having to be re-submitted if the user goes back through the survey.
      if (
        !dataIsEqual(
          this.props.questionState.data,
          this.props.questionState.postedData
        ) ||
        this.props.questionState.requestState === "POST_ERROR" ||
        this.props.question.type === "DISPLAY"
      ) {
        // Wait for props.responseMs to update
        await this.pauseTimer();
        this.props.postResponse(
          this.props.match.params.surveyId,
          this.props.question.questionId,
          this.props.question.type,
          this.props.questionState.data,
          this.props.questionState.responseMs
        );
      } else {
        this.skip();
      }
    }
  };

  isLastQuestion = () => {
    return this.props.questionIdx >= this.props.survey.questions.length - 1;
  };
  nextQuestionIsDisplay = () => {
    if (this.isLastQuestion()) return false;
    return (
      this.props.survey.questions[this.props.questionIdx + 1].type === "DISPLAY"
    );
  };

  // Toggle audio if question type is THINKALOUD
  toggleIfThinkAloud = () => {
    if (!this.props.question || !this.props.question.type) return;
    if (
      this.props.question.type.includes("THINKALOUD") ||
      this.props.question.startRecording
    ) {
      this.toggleAudioRecording(false);
    }
  };

  goBack = () => {
    if (this.props.questionState.isRecording || this.recordingVideo) {
      if (this.props.question.type.includes("THINKALOUD")) {
        this.toggleAudioRecording(false);
        this.pauseTimer();
        this.props.prevStep();
      } else {
        this.alert(strpLocalize("Please stop recording first."));
      }
    } else {
      this.pauseTimer();
      this.props.prevStep();
    }
  };

  skip = () => {
    let nextFunc = this.props.nextQuestion;
    if (this.isLastQuestion()) nextFunc = this.props.nextStep;

    if (this.props.questionState.isRecording || this.recordingVideo) {
      if (this.props.question.type.includes("THINKALOUD")) {
        this.toggleAudioRecording(false);
        nextFunc();
      } else {
        this.alert(strpLocalize("Please stop recording first."));
      }
    } else {
      nextFunc();
    }
  };

  getMainButtonText = () => {
    if (this.props.questionState.countdown > 0)
      return (
        strpLocalize("Wait") +
        ` ${secondsToString(this.props.questionState.countdown)}`
      );
    if (this.props.questionState.requestState === "POST_ATTEMPTED") {
      if (this.props.questionState.requestProgress !== undefined) {
        return (
          <MiniProgress
            value={this.props.questionState.requestProgress * 100.0}
          />
        );
      }
      return (
        <div className="sweet-loading">
          <ClipLoader size={"15px"} color={"white"} loading={true} />
        </div>
      );
    }

    if (this.props.survey.buttonType === "chevron") {
      return (
        <>
          {strpLocalize("Next")}
          <FontAwesomeIcon
            size="lg"
            icon={faChevronRight}
            className="chevron-right"
          />
        </>
      );
    }

    const s3PostError = this.props.questionState.requestState === "POST_ERROR";
    if (s3PostError) return strpLocalize("Try again");

    if (this.isLastQuestion()) {
      if (showDemographics(this.props.survey)) {
        return strpLocalize("Next");
      } else {
        return strpLocalize("Finish");
      }
    } else {
      return strpLocalize("Next");
    }
  };

  showPreviousButton() {
    if (this.props.survey.hidePreviousButton) {
      return false;
    } else {
      return !(
        this.props.survey.removeWelcomeScreen && this.props.questionIdx === 0
      );
    }
  }

  getFooter = () => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: strpLocalize(this.props.question.footer),
        }}
      />
    );
  };

  // Timer functions

  startTimer = () => {
    if (!this.state.timerRunning) {
      this.setState({
        startTime:
          Date.now() -
          (this.props.questionState.responseMs
            ? this.props.questionState.responseMs
            : 0),
        timerRunning: true,
      });
    }
  };

  pauseTimer = () => {
    if (this.state.timerRunning) {
      const diff = Date.now() - this.state.startTime;
      this.props.updateQuestionState({ responseMs: diff });
      this.setState({ timerRunning: false });
    }
  };

  handleVisibility = (isVisible) => {
    if (isVisible) {
      this.startTimer();
    } else {
      this.pauseTimer();
    }
  };

  alert = (message) => {
    this.statusRef.current.trigger(message);
  };

  render() {
    return (
      <div>
        <QuestionTypeSelector
          onSubmit={this.onSubmit.bind(this)}
          toggleAudioRecording={this.toggleAudioRecording}
          audioParams={{
            isRecording: this.props.questionState.isRecording,
            timeElapsed: this.props.questionState.timeElapsed,
            somethingRecorded: this.props.questionState.somethingRecorded,
            maxResponseLength:
              this.props.question.limitResponseLength &&
              this.props.question.maxResponseLength,
          }}
          videoParams={{
            onStartRecording: this.onStartRecordingVideo,
            onStopRecording: this.onStopRecordingVideo,
            maxResponseLength:
              this.props.question.limitResponseLength &&
              this.props.question.maxResponseLength,
            useNativeCamera: this.props.survey.useNativeCamera,
          }}
        />
        <StatusBar ref={this.statusRef} />
        <PageVisibility onChange={this.handleVisibility} />
        <div className="button-bar">
          {this.showPreviousButton() && (
            <PrevButton onClick={this.goBack}>
              {this.props.survey.buttonType === "chevron" && (
                <>
                  <FontAwesomeIcon
                    size="lg"
                    icon={faChevronLeft}
                    className="chevron-left"
                  />
                </>
              )}
            </PrevButton>
          )}
          <MainButton
            className={clsx(
              "next-button",
              !this.props.question.optional && "empty-skip",
              !this.showPreviousButton() && "empty-prev"
            )}
            onClick={this.postResponseAndProceed}
            disabled={
              this.props.questionState.requestState === "POST_ATTEMPTED" ||
              this.props.questionState.countdown > 0
            }
            startIcon={
              this.props.survey.buttonType === "chevron" ||
              this.props.questionState.countdown > 0 ||
              (this.props.questionState.requestState === "POST_ATTEMPTED" &&
                (this.props.question.type.includes("VIDEO") ||
                  this.props.question.type.includes("SCREEN") ||
                  this.props.question.type.includes("FILE"))) ? undefined : (
                <CheckIcon />
              )
            }
          >
            {this.getMainButtonText()}
          </MainButton>
          {this.props.question.optional && (
            <PrevButton onClick={this.skip} skip>
              {this.props.survey.buttonType === "chevron" && (
                <>
                  <FontAwesomeIcon
                    size="lg"
                    icon={faChevronRight}
                    className="chevron-right"
                  />
                </>
              )}
            </PrevButton>
          )}
        </div>
        {this.props.question.footer && (
          <div className="footer">{this.getFooter()}</div>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Question)
);
