import React, { Component } from "react";
import { connect } from "react-redux";
import MainButton from "../components/Buttons/MainButton";
import ClipLoader from "react-spinners/ClipLoader";
import { withRouter } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import { strpLocalize } from "../localization/localize";
import { postSessionData } from "../redux/actions";
import { trackPage } from "../analytics";

const mapStateToProps = (state) => {
  return {
    survey: state.survey,
    sessionId: state.sessionId,
    language: state.language,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    postSessionData: (surveyId, sessionId, data) =>
      dispatch(postSessionData(surveyId, sessionId, data)),
  };
};

class Welcome extends Component {
  getTitle = () => {
    if (this.props.survey && this.props.survey.title) {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: strpLocalize(this.props.survey.title),
          }}
        />
      );
    }
    return strpLocalize("Welcome");
  };

  getSubTitle = () => {
    if (this.props.survey && this.props.survey.subTitle) {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: strpLocalize(this.props.survey.subTitle),
          }}
        />
      );
    }
    return strpLocalize("This is an Audio Survey");
  };

  componentDidMount() {
    trackPage("Welcome", {
      sessionId: this.props.sessionId,
      surveyId: this.props.match.params.surveyId,
    });

    // Safari and mobile have a different audio context.
    // Consider not using mic-recorder-to-mp3.
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
  }

  render() {
    return (
      <div>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={10} md={8}>
            {this.props.img && (
              <img
                className="logo-welcome"
                src={this.props.img}
                alt="logo"
              ></img>
            )}
            <Typography variant="h1" className="title">
              {this.getTitle()}
            </Typography>
            <h2 className="subtitle">{this.getSubTitle()}</h2>
            <MainButton
              style={{ marginTop: 32, marginBottom: 32 }}
              onClick={this.props.clickToBegin}
              disabled={!this.props.survey}
            >
              <div className="sweet-loading">
                <ClipLoader
                  size={"15px"}
                  color={"white"}
                  loading={!this.props.survey}
                />
              </div>
              {this.props.survey && (
                <span>{strpLocalize("Click to Begin")}</span>
              )}
            </MainButton>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Welcome)
);
