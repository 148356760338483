import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import parseISO from "date-fns/parseISO";
import isValid from "date-fns/isValid";
import { strpLocalize } from "../../localization/localize";
import { isValidMonth, isValidDay } from "../../utils/utils";
import clsx from "clsx";

const Date = ({ onSubmit, data, align }) => {
  const [date, setDate] = useState(() => {
    if (data) {
      const initialDate = data.split("-");
      return {
        month: initialDate[1],
        day: initialDate[2],
        year: initialDate[0],
      };
    } else {
      return { month: "", day: "", year: "" };
    }
  });

  const [errors, setErrors] = useState({
    month: false,
    day: false,
    year: false,
  });

  const [generalError, setGeneralError] = useState(false);

  const handleChange = (evt) => {
    if (evt.target.value !== "" && !/^[0-9\b]+$/.test(evt.target.value)) return;

    setDate({ ...date, [evt.target.name]: evt.target.value });
  };

  const handleBlur = (evt) => {
    let error = false;
    if (evt.target.name === "month") {
      if (isValidMonth(evt.target.value)) {
        error = false;
      } else {
        error = true;
      }
    } else if (evt.target.name === "day") {
      if (isValidDay(evt.target.value)) {
        error = false;
      } else {
        error = true;
      }
    } else if (evt.target.name === "year") {
      if (evt.target.value < 3000) {
        error = false;
      } else {
        error = true;
      }
    }

    setErrors({ ...errors, [evt.target.name]: error });

    if (
      date.month &&
      date.day &&
      date.year &&
      !errors.month &&
      !errors.day &&
      !errors.year &&
      !isValid(parseISO(`${date.year}-${date.month}-${date.day}`))
    ) {
      setGeneralError(true);
    } else {
      setGeneralError(false);
    }

    onSubmit(`${date.year}-${date.month}-${date.day}`);
  };

  const inputIsInvalid = errors.month || errors.day || errors.year;

  return (
    <Paper
      style={{
        maxWidth: "400px",
        marginTop: 10,
        marginBottom: 10,
        padding: 25,
      }}
      className={clsx(
        align === "left" ? "ml-0" : "ml-auto",
        align === "right" ? "mr-0" : "mr-auto"
      )}
    >
      <form autoComplete="off">
        <div className="date-wrapper">
          <TextField
            id="date-field"
            color="primary"
            fullWidth
            label={strpLocalize("Month")}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ maxWidth: 50 }}
            value={date.month}
            name="month"
            onChange={handleChange}
            error={errors.month}
            onBlur={handleBlur}
            inputProps={{ maxLength: 2 }}
          />
          <span>{"/"}</span>
          <TextField
            id="day-field"
            color="primary"
            fullWidth
            label={strpLocalize("Day")}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ maxWidth: 50 }}
            value={date.day}
            name="day"
            onChange={handleChange}
            error={errors.day}
            onBlur={handleBlur}
            inputProps={{ maxLength: 2 }}
          />
          <span>{"/"}</span>
          <TextField
            id="year-field"
            color="primary"
            fullWidth
            label={strpLocalize("Year")}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ maxWidth: 100 }}
            value={date.year}
            name="year"
            onChange={handleChange}
            helperText={errors.year ? "Invalid date" : ""}
            error={errors.year}
            onBlur={handleBlur}
            inputProps={{ maxLength: 4 }}
          />
        </div>
        <span className="generic-error">
          {inputIsInvalid && strpLocalize("Invalid date")}
          {!inputIsInvalid &&
            generalError &&
            `${strpLocalize("Invalid date")} - ${strpLocalize(
              "date may not exist"
            )}`}
        </span>
      </form>
    </Paper>
  );
};

export default Date;
