import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { strpLocalize } from "../localization/localize";
import ContinueButton from "../components/Buttons/ContinueButton";

const styles = {
  root: {
    justify: "center",
    textAlign: "center",
    fontSize: "16px",
  },
};

function ContinuePopup(props) {
  const { classes, className } = props;

  return (
    <Dialog
      onClose={props.onClose}
      aria-labelledby="simple-dialog-title"
      open={props.open}
      className={clsx(classes.root, className)}
    >
      <DialogTitle id="simple-dialog-title">
        {strpLocalize("Survey Progress")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {strpLocalize(
            "You already have some responses saved for this survey session. Would you like to restart the survey or continue?"
          )}
        </DialogContentText>
      </DialogContent>
      <DialogContent>
        <ContinueButton className="restart" onClick={props.restart}>
          Restart
        </ContinueButton>
        <ContinueButton className="continue" onClick={props.continue}>
          Continue
        </ContinueButton>
      </DialogContent>
      <DialogContent></DialogContent>
    </Dialog>
  );
}

export default withStyles(styles)(ContinuePopup);
