import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import MainButton from "../components/Buttons/MainButton";
import PrevButton from "../components/Buttons/PrevButton";
import StatusBar from "../components/Alerts/StatusBar";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import { withRouter } from "react-router-dom";
import {
  addDemographics,
  postDemographics,
  nextStep,
  prevStep,
} from "../redux/actions";
import { strpLocalize } from "../localization/localize";
import clsx from "clsx";

const mapStateToProps = (state) => {
  return {
    survey: state.survey,
    sessionId: state.sessionId,
    preview: state.preview,
    ...state.demographics,
  };
};
const mapDispatchToProps = {
  addDemographics,
  postDemographics,
  nextStep,
  prevStep,
};

class Demographics extends Component {
  incomeOptions = [
    "0-10" + strpLocalize("k"),
    "10-25" + strpLocalize("k"),
    "25-50" + strpLocalize("k"),
    "50-75" + strpLocalize("k"),
    "75-100" + strpLocalize("k"),
    "100-125" + strpLocalize("k"),
    "125-150" + strpLocalize("k"),
    "150-175" + strpLocalize("k"),
    "175-200" + strpLocalize("k"),
    "200" + strpLocalize("k") + " +",
    strpLocalize("Prefer not to say"),
  ];
  statusRef = createRef();
  handleChange = (event, field) => {
    if (
      field === "age" &&
      event.target.value !== "" &&
      !parseInt(event.target.value)
    ) {
      return;
    }

    this.props.addDemographics({
      [field]:
        field === "age" ? parseInt(event.target.value, 10) : event.target.value,
    });
  };
  handleCheckboxChange = (event) => {
    if (event.target.checked) {
      this.props.addDemographics({ [event.target.name]: event.target.value });
    } else {
      this.props.addDemographics({ [event.target.name]: "" });
    }
  };
  getDemographics() {
    if (!this.props.survey.demographics) return {};
    return this.props.survey.demographics;
  }
  validateAndContinue = () => {
    if (this.props.survey.state === "DRAFT" || this.props.preview) {
      this.props.nextStep();
      return;
    }
    if (
      !this.getDemographics().ageOptional &&
      this.getDemographics().age &&
      !this.props.age
    ) {
      this.alert(strpLocalize("Please select an age."));
      return;
    }
    if (
      !this.getDemographics().genderOptional &&
      this.getDemographics().gender &&
      !this.props.gender
    ) {
      this.alert(strpLocalize("Please select a gender"));
      return;
    }
    if (
      !this.getDemographics().hHIncomeOptional &&
      this.getDemographics().hHIncome &&
      !this.props.hHIncome
    ) {
      this.alert(strpLocalize("Please select an income level."));
      return;
    }

    this.props.pauseTimer();

    this.props.postDemographics(
      this.props.match.params.surveyId,
      this.props.sessionId,
      this.props.nextStep
    );
  };

  showPreviousButton = () => {
    return !this.props.survey.hidePreviousButton;
  };

  alert = (message) => {
    this.statusRef.current.trigger(message);
  };

  render() {
    return (
      <div>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography variant="h2" className="title" gutterBottom>
              {strpLocalize("About You")}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          alignItems={
            this.props.survey.align === "left"
              ? "flex-start"
              : this.props.survey.align === "right"
              ? "flex-end"
              : "center"
          }
          spacing={2}
          style={{ marginBottom: 20 }}
        >
          {this.getDemographics().age && (
            <Grid item>
              <Paper className="demographics-item">
                <FormGroup>
                  <FormControl
                    style={{ width: "100%" }}
                    required={!this.getDemographics().ageOptional}
                  >
                    <InputLabel htmlFor="age">
                      {strpLocalize("Your Age")}
                    </InputLabel>
                    <Input
                      id="age"
                      onChange={(e) => this.handleChange(e, "age")}
                      name="age"
                      value={this.props.age || ""}
                      disabled={Boolean(this.props.age === "Prefer not to say")}
                    />
                  </FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.props.age === "Prefer not to say"}
                        onChange={this.handleCheckboxChange}
                        name="age"
                        value="Prefer not to say"
                        color="primary"
                      />
                    }
                    label="Prefer not to say"
                  />
                </FormGroup>
              </Paper>
            </Grid>
          )}

          {this.getDemographics().gender && (
            <Grid item>
              <Paper className="demographics-item">
                <FormControl
                  component="fieldset"
                  style={{ width: "180px" }}
                  required={!this.getDemographics().genderOptional}
                >
                  <FormLabel component="legend" style={{ margin: "5px" }}>
                    {strpLocalize("Your Gender")}
                  </FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    name="gender"
                    onChange={(e) => {
                      this.handleChange(e, "gender");
                    }}
                    defaultValue={this.props.gender}
                  >
                    <FormControlLabel
                      value="female"
                      control={<Radio color="primary" />}
                      label={strpLocalize("Female")}
                    />
                    <FormControlLabel
                      value="male"
                      control={<Radio color="primary" />}
                      label={strpLocalize("Male")}
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio color="primary" />}
                      label={strpLocalize("Other")}
                    />
                    <FormControlLabel
                      value="Prefer not to say"
                      control={<Radio color="primary" />}
                      label={strpLocalize("Prefer not to say")}
                    />
                  </RadioGroup>
                </FormControl>
              </Paper>
            </Grid>
          )}

          {this.getDemographics().hHIncome && (
            <Grid item>
              <Paper className="demographics-item">
                <FormControl
                  style={{ width: "180px" }}
                  required={!this.getDemographics().hHIncomeOptional}
                >
                  <InputLabel>{strpLocalize("Household Income")}</InputLabel>
                  <Select
                    onChange={(e) => {
                      this.handleChange(e, "hHIncome");
                    }}
                    defaultValue={this.props.hHIncome}
                  >
                    {this.incomeOptions.map((i) => (
                      <MenuItem key={`hHIncome-${i}`} value={i}>
                        {i}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Paper>
            </Grid>
          )}
        </Grid>
        <StatusBar ref={this.statusRef} align={this.props.align} />
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12}>
            {this.showPreviousButton() && (
              <PrevButton onClick={this.props.prevStep} />
            )}
            <MainButton
              className={clsx(
                "next-button",
                "empty-skip",
                !this.showPreviousButton() && "empty-prev"
              )}
              onClick={this.validateAndContinue}
            >
              <span>{strpLocalize("Finish")}</span>
            </MainButton>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Demographics)
);
