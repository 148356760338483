import React from "react";
import Typography from "@material-ui/core/Typography";
import MicHelp1 from "../../assets/img/help/mic-check-1.png";
import MicHelp2 from "../../assets/img/help/mic-check-2.png";
import MicHelp3 from "../../assets/img/help/mic-check-3.png";

export default function HelpContent(props) {
  return (
    <>
      <Typography variant="h6">Unsupported Devices & Browsers</Typography>
      <Typography variant="body2">
        Phonic surveys can be answered from almost all devices and browsers,
        however there are known problematic device/browser combinations.
      </Typography>
      <Typography variant="body2">
        <ul>
          <li>
            <b>
              Links opened inside other applications.
              {"  "}
            </b>
            For example, a link opened in the Facebook Messenger web view.
            Microphone access can only be obtained when the link is opened
            directly in the browser.
          </li>
          <br />
          <li>
            <b>
              Older Apple iOS devices (iPhones, iPads, etc) & non-Safari
              browsers.
              {"  "}
            </b>
            Older Apple devices block microphone access on all iOS browsers
            except Safari. If you are using one of these devices, consider make
            sure you are using Safari.
          </li>
          <br />
          <li>
            <b>
              Legacy Browsers.
              {"  "}
            </b>
            For example, Internet Explorer 6-11 or Edge 12-18. View a full list
            of supported browsers{" "}
            <a href="https://caniuse.com/#feat=mediarecorder" target="_blank">
              here
            </a>
            .
          </li>
        </ul>
      </Typography>
      <Typography variant="h6">Microphone Settings</Typography>
      <Typography variant="body2">
        Sometimes recording fails on mobile browsers because the browser does
        not have permission to access the microphone. This can be checked by
        going to your settings and verifying that the microphone is enabled. On
        iPhone, this can be found in Settings > Safari > Microphone > Enable.
      </Typography>
      <br />
      <Typography variant="h6">Microphone Quality</Typography>
      <Typography variant="body2">
        Unlike a native application, Phonic exists entirely on the web. This
        makes it as easy as possible for respondents to record audio responses
        since they don't have to download a seperate application. Unfortunately,
        there are a host of issues when recording audio in-browser that may
        compromise recording quality. However, these issues are generally only
        observed in mobile. High fidelity recordings can almost always be
        obtained on desktop browsers.
      </Typography>
      <br />
      <Typography variant="h6">HTTPS</Typography>
      <Typography variant="body2">
        Most browsers limit microphone access to sites loaded over HTTPS. If
        you're answering a survey on our survey.phonic.ai domain, we've handled
        this for you. If you're answering through a Phonic widget or integration
        with another platform then it's possible the web page is using plain
        HTTP. If an error occurs when you press recording, ensure that your url
        begins with https://.
      </Typography>
      <br />
      <Typography variant="h6">Microphone Check</Typography>
      <Typography variant="body2">
        The survey creator may choose to add a microphone check at the beginning
        of the survey. This audio is not saved anywhere. The purpose of this
        check is to verify that your microphone is working as expected.
        <br />
        <br />
        Some devices are configured so that the microphone can record audio but
        cannot play it back. As long as the waveform displayed appears contains
        an audio buffer, the recording is successful.
      </Typography>
      <img
        src={MicHelp1}
        className="help-img"
        alt="successful microphone test"
      />
      <Typography variant="body2">
        This is a successful microphone test. The microphone recorded audio
        which was successfully saved. You can proceed to the survey.
        <img
          src={MicHelp2}
          className="help-img"
          alt="failed microphone test 1"
        />
        The above image shows that the microphone was enabled, but no audio was
        recorded. Try refreshing the page or recording on a different device.
        <img
          src={MicHelp3}
          className="help-img"
          alt="failed microphone test 2"
        />
        The above image shows that the microphone is not enabled. You may have
        to check your audio settings or record on a different device.
      </Typography>
      <br />
      {props.includeContact && (
        <>
          <Typography variant="h6">Contact Us</Typography>
          <Typography variant="body2">
            If you have a question or concern not mentioned above, feel free to
            reach out to us at{" "}
            <a href="mailto:support@phonic.ai">support@phonic.ai</a> and a
            member of the team will assist you as soon as possible.
          </Typography>
        </>
      )}
    </>
  );
}
