import React, { Component } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Paper from "@material-ui/core/Paper";
import { fixedShuffleIndex } from "../../utils/utils";
import { strpLocalize } from "../../localization/localize";
import clsx from "clsx";

class CheckboxSelection extends Component {
  state = { arr: [], options: [] };
  componentWillMount() {
    if (this.props.data) {
      this.setState({
        arr: this.props.data,
      });
    } else {
      this.setState({
        arr: new Array(this.props.question.options.length).fill(false),
      });
    }

    if (this.props.question.randomize) {
      var shuffledOptions = fixedShuffleIndex(
        this.props.question.options,
        this.props.question.fixed
      );
      this.setState({ options: shuffledOptions });
    } else {
      this.setState({ options: this.props.question.options });
    }
  }

  getShuffledOptionsIndex = (option) => {
    return this.state.options.indexOf(option);
  };

  getRealOptionIndex = (option) => {
    return this.props.question.options.indexOf(option);
  };

  handleChange = (event) => {
    var realIndex = this.getRealOptionIndex(event.target.value);
    this.state.arr[realIndex] = !this.state.arr[realIndex];
    this.props.onSubmit(this.state.arr);
  };

  getSelectedClass = (option) => {
    return this.state.arr[this.getRealOptionIndex(option)] ? "selected" : "";
  };

  render() {
    return (
      <FormGroup row>
        {this.state.options.map((option, idx) => {
          return (
            <Paper
              key={`checkbox-selection-${idx}`}
              className={clsx("selection-paper", this.getSelectedClass(option))}
              square
            >
              <FormControlLabel
                style={{ float: "left" }}
                onChange={this.handleChange.bind(this)}
                control={
                  <Checkbox
                    className="selection"
                    value={option}
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                    defaultChecked={
                      this.state.arr[this.getRealOptionIndex(option)]
                    }
                  />
                }
                label={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: strpLocalize(option),
                    }}
                  />
                }
                labelplacement="right"
              />
            </Paper>
          );
        })}
      </FormGroup>
    );
  }
}

export default CheckboxSelection;
