import React from "react";
import clsx from "clsx";
import Fab from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { strpLocalize } from "../../localization/localize";

const useStyles = makeStyles({
  root: {
    border: "1px solid",
    borderRadius: 8,
    fontSize: 18,
    marginLeft: (skip) => (skip === true ? 10 : 0),
    marginRight: (skip) => (skip === true ? 0 : 10),
    height: 30,
    minWidth: 30,
    width: 30,
  },
});

const GeneralButton = ({ className, children, skip, ...other }) => {
  const classes = useStyles(skip);

  const text = skip ? strpLocalize("Skip") : strpLocalize("Previous");

  return (
    <Tooltip title={text} aria-label={text} placement="top">
      <Fab
        className={clsx(classes.root, className, "previous-button")}
        {...other}
        aria-label={text}
      >
        {children || (skip ? <ArrowRightIcon /> : <ArrowLeftIcon />)}
      </Fab>
    </Tooltip>
  );
};

export default GeneralButton;
