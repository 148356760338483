import React, { Component } from "react";
import MainButton from "../components/Buttons/MainButton";
import PrevButton from "../components/Buttons/PrevButton";
import Grid from "@material-ui/core/Grid";
import { strpLocalize } from "../localization/localize";

class Warning extends Component {
  render() {
    return (
      <Grid container direction="row" justifyContent="center">
        <Grid item style={{ width: "100%" }}>
          <h1 className="title">{strpLocalize("Mobile User Warning")}</h1>
          <div
            className="subtitle"
            style={{
              lineHeight: 1.4,
              marginTop: 8,
            }}
          >
            {strpLocalize(
              "It looks like you're on a mobile device. This survey contains screen recording questions, which isn't currently supported on your device. You can still proceed, but you will be unable to answer these questions."
            )}
          </div>
          <br />
          <br />
        </Grid>
        <Grid item>
          <PrevButton onClick={this.props.prevStep} />
          <MainButton onClick={this.props.nextStep} className="empty-skip">
            <span>{strpLocalize("Next")}</span>
          </MainButton>
        </Grid>
      </Grid>
    );
  }
}

export default Warning;
