import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import clsx from "clsx";

const BorderLinearProgress = withStyles({
  root: {
    height: 5,
    borderRadius: 20,
    backgroundColor: "rgba(0, 0, 0, 0.03)",
  },
  bar: {
    borderRadius: 16,
  },
})(LinearProgress);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

export default function ProgressBar(props) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, "progress-bar")}>
      <BorderLinearProgress
        className={classes.margin}
        variant="determinate"
        color="secondary"
        value={props.value}
      />
    </div>
  );
}
