import React, { Component } from "react";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Paper from "@material-ui/core/Paper";
import { fixedShuffleIndex } from "../../utils/utils";
import { strpLocalize } from "../../localization/localize";
import clsx from "clsx";

class RadioSelection extends Component {
  state = { arr: [], options: [] };

  componentWillMount() {
    if (this.props.question.randomize) {
      var shuffledOptions = fixedShuffleIndex(
        this.props.question.options,
        this.props.question.fixed
      );
      this.setState({ options: shuffledOptions }, this.populateArr);
    } else {
      this.setState({ options: this.props.question.options }, this.populateArr);
    }
  }

  populateArr = () => {
    // Fill the select arr with false values, or populate from the saved state data arr if provided.
    var arr = new Array(this.props.question.options.length).fill(false);
    if (this.props.data) {
      for (let i = 0; i < arr.length; i++) {
        if (this.props.data[i]) {
          arr[
            this.getShuffledOptionsIndex(this.props.question.options[i])
          ] = true;
        }
      }
    }
    this.setState({
      arr: arr,
    });
  };

  getShuffledOptionsIndex = (option) => {
    return this.state.options.indexOf(option);
  };

  getRealOptionIndex = (option) => {
    return this.props.question.options.indexOf(option);
  };

  handleChange = (event) => {
    var displayArr = new Array(this.props.question.options.length).fill(false);
    var submitArr = new Array(this.props.question.options.length).fill(false);

    var realIndex = this.getRealOptionIndex(event.target.value);
    var shuffledIndex = this.getShuffledOptionsIndex(event.target.value);

    displayArr[shuffledIndex] = true;
    submitArr[realIndex] = true;

    this.props.onSubmit(submitArr);
    this.setState({ arr: displayArr });
  };

  getSelectedClass = (idx) => {
    return this.state.arr[idx] ? "selected" : "";
  };

  render() {
    return (
      <RadioGroup row>
        {this.state.options.map((option, idx) => {
          return (
            <Paper
              key={`radio-selection-${idx}`}
              className={clsx("selection-paper", this.getSelectedClass(idx))}
              square
            >
              <FormControlLabel
                style={{ float: "left" }}
                control={
                  <Radio
                    className="selection"
                    checked={this.state.arr[idx]}
                    onChange={this.handleChange}
                    value={option}
                    color="primary"
                    inputProps={{ "aria-label": "primary" }}
                  />
                }
                label={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: strpLocalize(option),
                    }}
                  />
                }
                labelplacement="right"
              />
            </Paper>
          );
        })}
      </RadioGroup>
    );
  }
}

export default RadioSelection;
