import React, { Component } from "react";
import { connect } from "react-redux";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";

/**
 * This wrapper allows Alert to be opened imperatively. The parent needs a ref
 * on this component to call its method. If we ever switch to all function
 * components, a custom hook would be less clunky.
 */

const mapStateToProps = (state) => {
  return {
    align: state.survey && state.survey.align ? state.survey.align : "center",
  };
};

class StatusBar extends Component {
  state = { open: false, message: "" };

  timer = null;

  startCountdown = () => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.setState({ open: false });
    }, this.props.hideDuration);
  };

  trigger = (message) => {
    this.setState({ open: true, message }, this.startCountdown);
  };

  render() {
    return (
      <Collapse
        in={this.state.open}
        className={`survey-alert ${this.props.align}`}
      >
        <Alert severity="error" icon={false}>
          {this.state.message}
        </Alert>
      </Collapse>
    );
  }
}

StatusBar.defaultProps = {
  hideDuration: 2500,
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  StatusBar
);
