import React, { Component } from "react";
import { secondsToString } from "../../utils/utils";
import RecordButton from "../../components/Buttons/RecordButton";
import PlaybackButton from "../../components/Buttons/PlaybackButton";
import PlaybackProgress from "../../components/Progress/PlaybackProgress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophone,
  faPlay,
  faPause,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { strpLocalize } from "../../localization/localize";
import clsx from "clsx";

class AudioType extends Component {
  state = {
    playing: false,
    audio: null,
  };
  timeAllowedRef = React.createRef(null);
  timeAllowedRef2 = React.createRef(null);
  getRecorderText = () => {
    const showTime =
      this.props.audioParams.maxResponseLength ||
      this.props.audioParams.isRecording ||
      this.props.audioParams.somethingRecorded;
    const seconds = this.props.audioParams.maxResponseLength
      ? this.props.audioParams.maxResponseLength -
        (this.props.audioParams.timeElapsed || 0)
      : this.props.audioParams.timeElapsed || 0;

    return (
      <p
        className={clsx(
          "record-time-text",
          this.props.align === "left" ? "ml-0" : "ml-auto",
          this.props.align === "right" ? "mr-0" : "mr-auto",
          showTime ? "visibile" : "invisible"
        )}
      >
        {secondsToString(seconds)}
      </p>
    );
  };

  toggleAudioRecording = () => {
    if (!this.props.question.type.includes("THINKALOUD"))
      this.props.toggleAudioRecording();
    if (
      this.timeAllowedRef2.current &&
      this.timeAllowedRef2.current.classList.contains("stopped-recording2")
    ) {
      this.timeAllowedRef2.current.classList.remove("stopped-recording2");
    }
  };

  getRecordButtonText = () => {
    if (this.props.audioParams.isRecording)
      return strpLocalize("Stop Recording");
    if (!this.props.audioParams.somethingRecorded) {
      return (
        <>
          <FontAwesomeIcon icon={faMicrophone} />
          <span style={{ marginLeft: 10 }}>{strpLocalize("Record")}</span>
        </>
      );
    }
    return strpLocalize("Re-record");
  };

  generatePlayback() {
    const audioUrl = URL.createObjectURL(this.props.file);
    var audio = new Audio(audioUrl);
    audio.addEventListener("timeupdate", (e) => {
      this.setState({
        playbackProgress:
          (this.state.audio.currentTime / this.state.audio.duration) * 100.0,
      });
    });
    audio.addEventListener("ended", (e) => {
      this.togglePlayback(e);
      setTimeout(() => {
        this.setState({ playbackProgress: 0 });
      }, 500);
    });
    this.setState({ audio: audio });
  }

  componentDidMount() {
    if (this.props.file) this.generatePlayback();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.file !== this.props.file) {
      this.generatePlayback();
    }

    if (
      prevProps.audioParams.timeElapsed !==
        this.props.audioParams.timeElapsed &&
      this.props.audioParams.isRecording &&
      this.props.audioParams.maxResponseLength &&
      this.props.audioParams.timeElapsed >=
        this.props.audioParams.maxResponseLength
    ) {
      this.toggleAudioRecording();

      try {
        this.timeAllowedRef.current.classList.add("stopped-recording");
        this.timeAllowedRef2.current.classList.add("stopped-recording2");
        setTimeout(() => {
          this.timeAllowedRef.current.classList.remove("stopped-recording");
        }, 160);
      } catch (e) {
        console.log(e.message);
      }
    }
  }

  componentWillUnmount() {
    if (this.state.playing) {
      this.state.audio.pause();
    }
  }

  togglePlayback = (event) => {
    event.stopPropagation();
    if (this.state.playing) {
      this.state.audio.pause();
    } else {
      if (!this.state.audio) {
        alert(
          "Audio not found on client. If you're continuing with a survey started previously, your data will still be saved."
        );
        return;
      }
      this.state.audio.play();
    }
    this.setState({ playing: !this.state.playing });
  };

  getPlayPauseText() {
    if (!this.props.audioParams.somethingRecorded) return;
    if (this.state.playing)
      return (
        <FontAwesomeIcon icon={faPause} style={{ position: "absolute" }} />
      );
    return <FontAwesomeIcon icon={faPlay} style={{ position: "absolute" }} />;
  }

  render() {
    return (
      <div>
        {this.props.audioParams.maxResponseLength && (
          <div
            className={clsx(
              "time-allowed",
              this.props.align === "left" ? "ml-0" : "ml-auto",
              this.props.align === "right" ? "mr-0" : "mr-auto"
            )}
            ref={this.timeAllowedRef2}
          >
            <FontAwesomeIcon icon={faClock} />
            &nbsp;{strpLocalize("Time Limit")}
          </div>
        )}
        {this.getRecorderText()}
        <div>
          <RecordButton
            onClick={this.toggleAudioRecording}
            ref={this.timeAllowedRef}
            className="ripple-base"
          >
            {this.getRecordButtonText()}
          </RecordButton>
          {this.props.question.showAudioPlayback &&
            this.props.audioParams.somethingRecorded && (
              <PlaybackButton
                onClick={(event) => this.togglePlayback(event)}
                style={{ marginLeft: 12 }}
              >
                {this.getPlayPauseText()}
                <PlaybackProgress progress={this.state.playbackProgress} />
              </PlaybackButton>
            )}
        </div>
      </div>
    );
  }
}

export default AudioType;
