import React, { Component } from "react";
import VideoRecorder from "react-video-recorder";
import VideoOverlay from "../../components/VideoRecording/VideoOverlay";
import DisconnectedView from "../../components/VideoRecording/DisconnectedView";
import LoadingView from "../../components/VideoRecording/LoadingView";
import ErrorView from "../../components/VideoRecording/ErrorView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { strpLocalize } from "../../localization/localize";
import clsx from "clsx";

class Video extends Component {
  timeAllowedRef2 = React.createRef();

  startRecording = () => {
    this.props.videoParams.onStartRecording();
    if (
      this.timeAllowedRef2.current &&
      this.timeAllowedRef2.current.classList.contains("stopped-recording2")
    ) {
      this.timeAllowedRef2.current.classList.remove("stopped-recording2");
    }
  };

  stopRecording = () => {
    this.props.videoParams.onStopRecording();
    if (this.timeAllowedRef2.current) {
      this.timeAllowedRef2.current.classList.add("stopped-recording2");
    }
  };

  render() {
    const marginLeft = this.props.align === "left" ? 0 : "auto";
    const marginRight = this.props.align === "right" ? 0 : "auto";

    return (
      <div style={{ margin: "12px 0" }}>
        {this.props.videoParams.maxResponseLength && (
          <div
            className="time-allowed"
            ref={this.timeAllowedRef2}
            style={{ marginLeft, marginRight }}
          >
            <FontAwesomeIcon icon={faClock} />
            &nbsp;{strpLocalize("Time Limit")}
          </div>
        )}
        <div
          style={{
            maxWidth: 600,
            marginLeft,
            marginRight,
            overflow: "visible",
          }}
          className={clsx("rounded", this.props.align)}
        >
          <VideoRecorder
            ref={this.videoRecorderRef}
            useNativeCamera={this.props.videoParams.useNativeCamera}
            onRecordingComplete={(videoBlob) => {
              // Turn videoblob into file.
              try {
                videoBlob.name = `response_video.webm`;
              } catch (err) {
                // Vides from mobile camera app already have file names and throw exception.
              }
              this.props.onSubmit(videoBlob);
            }}
            timeLimit={
              parseInt(this.props.videoParams.maxResponseLength, 10) * 1000
            }
            onStartRecording={this.startRecording}
            onStopRecording={this.stopRecording}
            renderDisconnectedView={() => (
              <DisconnectedView
                message={
                  <div
                    className="flex items-center message-wrapper"
                    style={{ height: 100 }}
                  >
                    To record video, your browser may request access to your
                    camera and microphone.
                  </div>
                }
              />
            )}
            renderVideoInputView={({ videoInput }) => (
              <>
                <DisconnectedView
                  message={
                    <div className="flex items-center message-wrapper">
                      To record video, your device may request access to your
                      camera and microphone.
                    </div>
                  }
                />
                {videoInput}
              </>
            )}
            renderActions={VideoOverlay}
            renderLoadingView={LoadingView}
            renderErrorView={ErrorView}
            replayVideoAutoplayAndLoopOff
            showReplayControls
          />
        </div>
      </div>
    );
  }
}

export default Video;
