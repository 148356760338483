import React from "react";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 64,
    minWidth: 200,
    margin: "10px 0",
    fontSize: 20,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#E45049",
    color: "white",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#FA544B",
    },
    [theme.breakpoints.down("xs")]: {
      height: 50,
      minWidth: 150,
      fontSize: 18,
    },
  },
}));

const GeneralButton = React.forwardRef(
  ({ className, children, ...other }, ref) => {
    const classes = useStyles();

    return (
      <Button
        className={clsx(classes.root, className, "record-button")}
        {...other}
        ref={ref}
      >
        {children}
      </Button>
    );
  }
);

export default GeneralButton;
