import React from "react";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 64,
    width: 64,
    marginLeft: 5,
    fontSize: 16,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#E45049",
    color: "white",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#FA544B",
    },
    [theme.breakpoints.down("xs")]: {
      borderRadius: 8,
      height: 50,
      width: 50,
      fontSize: 18,
    },
  },
}));

function GeneralButton({ className, children, ...other }) {
  const classes = useStyles();

  return (
    <Button
      className={clsx(classes.root, className, "record-button")}
      {...other}
    >
      {children}
    </Button>
  );
}

export default GeneralButton;
