import * as RecordRTC from "recordrtc";
const MicRecorder = require("mic-recorder-to-mp3");

export class Recorder {
  constructor(format) {
    if (format === "wav") {
      this.recorder = new WAVRecorder();
    } else {
      // Default to MP3
      this.recorder = new MP3Recorder();
    }
  }

  start = () => {
    return this.recorder.start();
  };
  stop = () => {
    return this.recorder.stop();
  };
  getRecordingDuration = () => {
    return this.recorder.duration;
  };
}

export class MP3Recorder {
  constructor() {
    this.recorder = new MicRecorder({
      bitRate: 128,
    });
    this.startTime = null;
    this.duration = 0;
  }

  start = () => {
    return new Promise((resolve, reject) => {
      this.recorder
        .start()
        .then(() => {
          this.startTime = new Date().getTime();
          resolve();
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  stop = () => {
    return new Promise((resolve, reject) => {
      this.recorder
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
          this.duration = new Date().getTime() - this.startTime;
          const file = new File(buffer, "phonic.mp3", {
            type: blob.type,
            lastModified: Date.now(),
          });
          resolve(file);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
}

export class WAVRecorder {
  constructor() {
    this.startTime = null;
    this.duration = 0;
  }

  start = () => {
    return new Promise((resolve, reject) => {
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
        })
        .then(async (stream) => {
          this.recorder = RecordRTC(stream, {
            type: "audio",
            mimeType: "audio/wav",
            recorderType: RecordRTC.StereoAudioRecorder, // force for all browsers
            numberOfAudioChannels: 2,
          });
          await this.recorder.startRecording();
          this.startTime = new Date().getTime();
          resolve();
        })
        .catch((e) => reject(e));
    });
  };

  stop = () => {
    return new Promise((resolve, reject) => {
      try {
        this.recorder.stopRecording(() => {
          this.duration = new Date().getTime() - this.startTime;
          let blob = this.recorder.getBlob();
          const file = new File([blob], "phonic.wav", {
            type: blob.type,
            lastModified: Date.now(),
          });
          resolve(file);
        });
      } catch (e) {
        reject(e);
      }
    });
  };
}
