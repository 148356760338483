import React from "react";

import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Typography,
  FormControl,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import HelpContent from "../Help/HelpContent";
import Checkmark from "../Help/Checkmark";
import { strpLocalize } from "../../localization/localize";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class HelpPopup extends React.Component {
  state = { page: "help", feedback: "", email: "", error: false };
  contactSupport = () => {
    this.setState({ page: "contact" });
  };
  goBack = () => {
    this.setState({ page: "help" });
  };
  handleClose = () => {
    this.props.handleClose();
  };
  submit = () => {
    if (!this.state.feedback) {
      this.setState({ error: true });
      return;
    }
    this.props.submitFeedback(this.state.feedback, this.state.email);
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        onClose={this.handleClose}
        aria-describedby="help-popup-dialog"
      >
        {this.state.page === "help" && (
          <>
            <DialogTitle>
              {strpLocalize("Having a technical problem?")}
              <IconButton
                size="small"
                edge="end"
                style={{ float: "right" }}
                onClick={this.handleClose}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Typography variant="body2">
                  Check out the debugging steps below, or get in touch with our
                  team.
                </Typography>
              </DialogContentText>
              <HelpContent />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose}>Cancel</Button>
              <Button onClick={this.contactSupport}>Contact Support</Button>
            </DialogActions>
          </>
        )}
        {this.state.page === "contact" && (
          <>
            <DialogTitle>
              {strpLocalize("Contact Support")}
              <IconButton
                size="small"
                edge="end"
                style={{ float: "right" }}
                onClick={this.handleClose}
              >
                <CloseIcon />
              </IconButton>
              <IconButton
                size="small"
                edge="end"
                style={{ float: "right", marginRight: 8 }}
                onClick={this.goBack}
              >
                <KeyboardBackspaceIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent style={{ minHeight: 200 }}>
              {this.props.feedback &&
                this.props.feedback.requestState === "POST_SUCCESS" && (
                  <>
                    <Checkmark large />
                    <Typography variant="body2" style={{ textAlign: "center" }}>
                      Thank you for the feedback. We'll be in touch.
                    </Typography>
                  </>
                )}
              {this.props.feedback &&
                this.props.feedback.requestState === "POST_ERROR" && (
                  <>
                    <Typography variant="body2" style={{ textAlign: "center" }}>
                      We've run into an error submitting your feedback. Please
                      refresh and try again.
                    </Typography>
                  </>
                )}
              {!this.props.feedback && (
                <>
                  <DialogContentText>
                    <Typography variant="body2">
                      Tell us a bit about the problem you're experiencing.
                    </Typography>
                  </DialogContentText>
                  <FormControl style={{ width: "100%", marginBottom: 20 }}>
                    <TextField
                      id="feedback-outlined-secondary"
                      value={this.state.feedback}
                      placeholder="I'm having an issue..."
                      variant="outlined"
                      color="primary"
                      required
                      multiline
                      rows="4"
                      error={this.state.error}
                      fullWidth
                      onChange={(e) => {
                        this.setState({ feedback: e.target.value });
                      }}
                    />
                  </FormControl>
                  <DialogContentText>
                    <Typography variant="body2">
                      What is your email so that we can get in touch?
                    </Typography>
                  </DialogContentText>
                  <FormControl style={{ width: "100%" }}>
                    <TextField
                      id="feedback-outlined-secondary"
                      value={this.state.email}
                      placeholder="example@email.com"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onChange={(e) => {
                        this.setState({ email: e.target.value });
                      }}
                    />
                  </FormControl>
                </>
              )}
            </DialogContent>
            {(!this.props.feedback ||
              this.props.feedback.requestState === "POST_ERROR") && (
              <DialogActions>
                <Button onClick={this.submit}>Send Feedback</Button>
              </DialogActions>
            )}
          </>
        )}
      </Dialog>
    );
  }
}
