import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    borderRadius: 5,
    border: 0,
    height: 48,
    padding: "0 20px",
    marginRight: 20,
    marginLeft: 20,
    "&:hover": {
      background: "rgba(255,255,255,0.5)",
      boxShadow: "none",
    },
    minWidth: 120,

    fontFamily: "Roboto, sans-serif",
    fontSize: 18,
    textTransform: "none",
    fontStyle: "normal",
    fontSize: 18,
    lineHeight: 28,
  },
};

function ContinueButton(props) {
  const { classes, children, className, ...other } = props;

  return (
    <Button
      className={clsx(classes.root, className, "continue-button")}
      {...other}
    >
      {children || "class names"}
    </Button>
  );
}

ContinueButton.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(ContinueButton);
