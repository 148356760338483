import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { strpLocalize } from "../localization/localize";
import { addQueryParams } from "../utils/utils";
import { postSessionData } from "../redux/actions";
import { trackPage } from "../analytics";
import { dispatchEventScreenedOut } from "../events";

const mapStateToProps = (state) => {
  return { survey: state.survey, sessionId: state.sessionId };
};
const mapDispatchToProps = (dispatch) => {
  return {
    postSessionData: (surveyId, sessionId, data) =>
      dispatch(postSessionData(surveyId, sessionId, data)),
  };
};

class ScreenOut extends Component {
  constructor(props) {
    super(props);
    this.props.postSessionData(
      this.props.match.params.surveyId,
      this.props.sessionId,
      { completionStatus: "SCREENED_OUT" }
    );
  }
  componentDidMount() {
    dispatchEventScreenedOut();
    trackPage("Screen Out", {
      sessionId: this.props.sessionId,
      surveyId: this.props.match.params.surveyId,
    });
  }

  getURL = () => {
    var redirectUrl = this.props.survey.screenOutLink;
    if (
      this.props.match.params.surveyId === "603831cadbcea0ab8733af2e" &&
      this.props.location.search &&
      this.props.location.search.includes("panel=Cint")
    )
      redirectUrl =
        "https://s.cint.com/Survey/EarlyScreenOut?ProjectToken=56897854-c104-1b13-af90-3e21af79a76a";

    return addQueryParams(
      strpLocalize(redirectUrl),
      {
        phonicSessionId: this.props.sessionId,
      },
      this.props.location.search
    );
  };

  getTitle = () => {
    if (this.props.survey) {
      if (this.props.survey.screenOutTitle)
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: strpLocalize(this.props.survey.screenOutTitle),
            }}
          />
        );

      if (this.props.survey.screenOutLink)
        return strpLocalize("You're Almost Done!");
    }
    return strpLocalize("You're Done!");
  };

  getSubTitle = () => {
    if (this.props.survey && this.props.survey.screenOutLink) {
      return (
        <div>
          {strpLocalize(
            this.props.survey.screenOutSubTitle
              ? this.props.survey.screenOutSubTitle
              : "Follow this link to complete your response"
          )}
          <br />
          <a href={this.getURL()}>
            {strpLocalize(this.props.survey.screenOutLink)}
          </a>
        </div>
      );
    }
    if (this.props.survey && this.props.survey.screenOutSubTitle) {
      return <div>{this.props.survey.screenOutSubTitle}</div>;
    }
    return (
      <div>
        {strpLocalize("Thank you for your responses. You can close this tab.")}
      </div>
    );
  };

  render() {
    if (
      this.props.survey &&
      this.props.survey.screenOutLink &&
      this.props.survey.redirectToScreenOutLink
    ) {
      window.location.href = this.getURL();
    }

    return (
      <>
        <div>
          <Typography variant="h1" className="title">
            {this.getTitle()}
          </Typography>
          <h2 className="subtitle">{this.getSubTitle()}</h2>
        </div>
        <div style={{ marginTop: 30 }} />
      </>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ScreenOut)
);
