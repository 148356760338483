import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import MainButton from "../components/Buttons/MainButton";
import PrevButton from "../components/Buttons/PrevButton";
import StatusBar from "../components/Alerts/StatusBar";
import ConfirmationPopup from "../components/Popups/ConfirmationPopup";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import CheckIcon from "@material-ui/icons/Check";
import { withRouter } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import {
  addDemographics,
  postDemographics,
  nextStep,
  prevStep,
} from "../redux/actions";
import { isValidEmail, getAlignValue } from "../utils/utils";
import { strpLocalize } from "../localization/localize";

const mapStateToProps = (state) => {
  return {
    survey: state.survey,
    sessionId: state.sessionId,
    preview: state.preview,
    ...state.demographics,
    requestState: state.requestStates["Demographics"],
  };
};
const mapDispatchToProps = {
  addDemographics,
  postDemographics,
  nextStep,
  prevStep,
};

class PersonalInfo extends Component {
  state = { confirmationPopupOpen: false };

  statusRef = createRef();

  handleChange = (event, field) => {
    this.props.addDemographics({ [field]: event.target.value });
  };
  getDemographics() {
    if (!this.props.survey.demographics) return {};
    return this.props.survey.demographics;
  }
  validateAndContinue = () => {
    if (this.props.survey.state === "DRAFT" || this.props.preview) {
      this.props.nextStep();
      return;
    }

    if (
      !this.getDemographics().firstNameOptional &&
      this.getDemographics().firstName &&
      !this.props.firstName
    ) {
      this.alert(strpLocalize("Please enter your first name."));
      return;
    }
    if (
      !this.getDemographics().lastNameOptional &&
      this.getDemographics().lastName &&
      !this.props.lastName
    ) {
      this.alert(strpLocalize("Please enter your last name."));
      return;
    }
    if (
      !this.getDemographics().emailOptional &&
      this.getDemographics().email &&
      !isValidEmail(this.props.email)
    ) {
      this.alert(strpLocalize("Please enter a valid email."));
      return;
    }
    this.props.postDemographics(
      this.props.match.params.surveyId,
      this.props.sessionId,
      this.props.nextStep,
      this.duplicateEmailAskForConfirmation
    );
  };

  // If the backend detects a duplicate email or other session
  duplicateEmailAskForConfirmation = () => {
    this.setState({ confirmationPopupOpen: true });
  };

  getMainButtonText = () => {
    if (this.props.requestState === "ATTEMPTED") {
      return (
        <div className="sweet-loading">
          <ClipLoader size={"15px"} color={"white"} loading={true} />
        </div>
      );
    } else if (this.props.requestState === "ERROR") {
      return strpLocalize("Try again");
    } else {
      return strpLocalize("Next");
    }
  };

  alert = (message) => {
    this.statusRef.current.trigger(message);
  };

  render() {
    return (
      <div>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <h2 className="title" style={{ marginBottom: 20 }}>
              {strpLocalize("About You")}
            </h2>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent={getAlignValue(this.props.align, "justifyContent")}
          alignItems="center"
          spacing={2}
          style={{ marginBottom: 20 }}
        >
          {this.getDemographics().firstName && (
            <Grid item xs={8} sm={6} md={5} lg={4}>
              <Paper style={{ padding: "10px" }}>
                <FormControl style={{ width: "100%" }}>
                  <TextField
                    id="outlined-secondary"
                    label="First Name"
                    variant="outlined"
                    color="primary"
                    required={!this.getDemographics().firstNameOptional}
                    onChange={(e) => {
                      this.handleChange(e, "firstName");
                    }}
                    fullWidth
                    defaultValue={this.props.firstName}
                  />
                </FormControl>
              </Paper>
            </Grid>
          )}

          {this.getDemographics().lastName && (
            <Grid item xs={8} sm={6} md={5} lg={4}>
              <Paper style={{ padding: "10px" }}>
                <FormControl style={{ width: "100%" }}>
                  <TextField
                    id="outlined-secondary"
                    label="Last Name"
                    variant="outlined"
                    color="primary"
                    required={!this.getDemographics().lastNameOptional}
                    onChange={(e) => {
                      this.handleChange(e, "lastName");
                    }}
                    fullWidth
                    defaultValue={this.props.lastName}
                  />
                </FormControl>
              </Paper>
            </Grid>
          )}

          {this.getDemographics().email && (
            <Grid item xs={8} sm={12} md={10} lg={8}>
              <Paper style={{ padding: "10px" }}>
                <FormControl style={{ width: "100%" }}>
                  <TextField
                    id="outlined-secondary"
                    label="Email"
                    variant="outlined"
                    color="primary"
                    required={!this.getDemographics().emailOptional}
                    onChange={(e) => {
                      this.handleChange(e, "email");
                    }}
                    fullWidth
                    defaultValue={this.props.email}
                  />
                </FormControl>
              </Paper>
            </Grid>
          )}
        </Grid>
        <StatusBar ref={this.statusRef} />
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12}>
            <PrevButton onClick={this.props.prevStep} />
            <MainButton
              className="next-button empty-skip"
              onClick={this.validateAndContinue}
              startIcon={<CheckIcon />}
              disabled={this.props.requestState === "ATTEMPTED"}
            >
              {this.getMainButtonText()}
            </MainButton>
          </Grid>
        </Grid>
        <ConfirmationPopup
          open={this.state.confirmationPopupOpen}
          cancel={() => this.setState({ confirmationPopupOpen: false })}
          confirm={this.props.nextStep}
          title="It looks like you've already taken this survey."
          body="Are you sure you want to continue?  Your old responses will be overwritten."
        />
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PersonalInfo)
);
