var Localize = require("localize");
var translations = require("./translations.json");

export const localize = new Localize(translations, null, "default");

localize.setLocale("en");
localize.throwOnMissingTranslation(false);

export function strpLocalize(s) {
  if (typeof s === "string") {
    s = s.replace(/\0/g, "");
    // s = s.replace("\n", "");
  }
  return localize.translate(s);
}

export function replaceStrOrLocalize(text, translations, locale) {
  if (locale in translations) {
    return translations[locale];
  } else {
    // If the explicit text translation isn't provided for this language,
    // try to lookup in the rest of the translations.
    return strpLocalize(text);
  }
}
